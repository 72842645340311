import {Injectable} from '@angular/core';
import {API} from '../utils/constants/constant';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  constructor(private http: HttpClient) {
  }

  getModelList(filter): Observable<any> {
    return this.http.get(API.modelList + '?keyword=' + filter.keyword + '&page=' +
      filter.page + '&sex=' + filter.sex + '&take=' + filter.take + '&sort=' + filter.sort + '&order=' + filter.order);
  }

  updatePassword(password: any): Observable<any> {
    return this.http.put(API.updatePassword, password);
  }

  getModelDropdownList(): Observable<any> {
    return this.http.get(API.modelDropdown);
  }

  checkPerformerAllow(data): Observable<any> {
    return this.http.get(API.checkPerformerAllow + '?objectId=' + data.objectId + '&userId=' + data.userId);
  }

  checkBlock(data): Observable<any> {
    return this.http.get(API.checkBlock + '?objectId=' + data.objectId + '&userId=' + data.userId);
  }
}
