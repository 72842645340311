import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {API} from '../utils/constants/constant';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminProductCategoriesService {

  constructor(private http: HttpClient) {
  }

  getProductsList(filter): Observable<any> {
    return this.http.get(API.modelProductCatagories + '?keyword=' + filter.keyword + '&page=' +
      filter.page + '&take=' + filter.take + '&order=' + filter.order + '&sort=' + filter.sort);
  }

  createProductCategory(data): Observable<any> {
    return this.http.post(API.createProductCatagories, data);
  }

  deleteProductCategory(id): Observable<any> {
    return this.http.delete(API.DeleteProductCatagories + id);
  }

  getProductCategoryDetails(id): Observable<any> {
    return this.http.get(API.modelProductCatagory + id);
  }
  updateProductCategory(id, data): Observable<any>{
    return this.http.put(API.UpdateProductCatagory + id, data);
  }
  getModelDropdownList(): Observable<any> {
    return this.http.get(API.modelDropdown);
  }
}
