<div class="body">
  <div class="row ">
    <div class="mb-0 col-lg-4 col-sm-6">
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-label>{{'search' | translate}}</mat-label>
        <input matInput [(ngModel)]="keyword" [maxlength]="maxSearchLength" (keyup.enter)="applySearchFilter()"
               (blur)="applySearchFilter()">
        <mat-icon matSuffix (click)="applySearchFilter()">search</mat-icon>
      </mat-form-field>
    </div>
    <div class="mb-0 ml-lg-auto col-lg-4 col-sm-6">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>{{'sort'|translate}}</mat-label>
        <mat-select [(value)]="selectedSort" (selectionChange)="onSaleVideoSelectChange()">
          <mat-option *ngFor="let sort of sorts" [value]="sort">
            {{sort| translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="videosList.length">
    <div class="row">
      <div *ngFor="let Video of videosList" class="mb-0 col-xl-3 col-lg-4 col-sm-6">
        <mat-card>
          <mat-card-header>
            <img mat-card-avatar src="{{performerProfile | checkImage}}">
            <mat-card-title matTooltip="{{Video?.name}}" style="cursor:pointer;"
                            (click)="navigateTo(Video)">{{Video?.name}}</mat-card-title>
            <mat-card-subtitle>{{Video?.updatedAt | date}}</mat-card-subtitle>
            <div class="header-dropdown" *ngIf="role=='user'">
              <button mat-icon-button [matMenuTriggerFor]="videoMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #videoMenu="matMenu">
                <button mat-menu-item (click)="addFavOrWatch(Video ,false)">
                  <mat-icon>access_time</mat-icon>
                  <span>{{'watchLater'|translate}}</span>
                </button>
                <button mat-menu-item (click)="addFavOrWatch(Video ,true)">
                  <mat-icon>star</mat-icon>
                  <span>{{'addFav'|translate}}</span>
                </button>
              </mat-menu>
            </div>
          </mat-card-header>
          <div class="example-full-width model_image" (mouseenter)="loadPreview(Video)" (mouseleave)="stopPreview()">
            <!--          <iframe class="video-container" width="100%" [src]="video.filePath | safe" allow="false"></iframe>-->
            <a class="video-thumb" (click)="navigateTo(Video)" style="cursor: pointer">
              <img *ngIf="Video.notSubscribe" [src]='Video.notSubscribe | checkImage' class="orginal-img">
              <img *ngIf="!Video.notSubscribe" [src]="Video?.thumbs[Video.previewIndex] | checkImage"
                   alt="{{Video?.name}}" class="orginal-img">
              <img *ngIf="Video.notSubscribe" [src]='Video.notSubscribe | checkImage' class="image-background">
              <img *ngIf="!Video.notSubscribe" [src]="Video?.thumbs[Video.previewIndex] | checkImage"
                   alt="{{Video?.name}}" class="image-background">
            </a>
          </div>
          <mat-card-actions>
            <div fxLayout="row" class="align-items-center">
              <button mat-icon-button style="cursor: text">
                <mat-icon class="greyColor" style="cursor: text">thumb_up</mat-icon>
              </button>
              <span>({{Video?.stats?.totalLike}})</span>
              <button mat-icon-button style="cursor: text">
                <mat-icon class="greyColor" style="cursor: text">comment</mat-icon>
              </button>
              <span>({{Video?.stats?.totalComment}})</span>
              <button mat-icon-button style="cursor: text">
                <mat-icon class="greyColor" style="cursor: text">remove_red_eye</mat-icon>
              </button>
              <span>({{Video?.stats?.totalView}})</span>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
      <div class="row" *ngIf="totalCurrentVideos < totalActualVideos">
        <button mat-raised-button (click)="loadMore()" color="primary"
                class="align-center">{{'loadMore'|translate}}</button>
      </div>
    </div>
  </div>
  <div *ngIf="!totalActualVideos" class="row">
    <p class="no-record-found">{{'noRecordFound' | translate}}</p>
  </div>
</div>
