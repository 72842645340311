import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {API} from '../utils/constants/constant';

interface AlbumInterface {
   albumId: string;
   albumName: string;
}


@Injectable({
  providedIn: 'root'
})
export class ModelAlbumsService {

  public albumId: string;
  public albumName: string;
  public picturesListener = new BehaviorSubject(false);

  constructor(private http: HttpClient) {
  }

  getAlbums(data): Observable<any> {
    if (data.isMe) {
      return this.http.get(API.modelAlbums + '?performerId=' + data.performerId);
    }
    else{
      return this.http.get(API.modelAlbums + '?performerId=' + data.performerId + '&test=test' + '&id=' + data.performerId);
    }
  }

  getPhotos(data): Observable<any> {
    return this.http.get(API.modelPhoto + '?albumId=' + data.albumId + '&performerId=' + data.performerId + '&take=' + data.take);
  }

  getAlbumId(): AlbumInterface {
    return {
      albumId: this.albumId,
      albumName: this.albumName
    };
  }

  setAlbumId(albumId, albumName): void {
    this.albumId = albumId;
    this.albumName = albumName;
  }
  getPerformerAlbums(data): Observable<any>{
    return this.http.get(API.albumList + '?page=' + data.page + '&performerId=' + data.performerId + '&take=' + data.take
    + '&sort=' + data.sort + '&order=' + data.order + '&keyword=' + data.keyword);
  }
  createAlbum(data): Observable<any>{
    return this.http.post(API.createAlbum , data);
  }
  deleteAlbum(id): Observable<any>{
    return this.http.delete(API.deleteAlbum + '?id=' + id);
  }
  getAlbumDetail(id): Observable<any>{
    return this.http.get(API.getAlbumDetail + '?id=' + id);
  }
  getAlbumPhotos(data): Observable<any>{
    return this.http.get(API.photoList + '?albumId=' + data);
  }
  updateAlbum(id, data): Observable<any>{
    return this.http.put(API.updateAlbum + id, data);
  }
  getPerformerAlbum(performerId): Observable<any>{
    return this.http.get(API.albumList + '?performerId=' + performerId);
  }
}
