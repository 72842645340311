import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API} from './utils/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private http: HttpClient) {
  }

  getMessageGroups(filter): Observable<any> {
    return this.http.get(API.messageGroups + '?type=' + filter.type + '&page=' + filter.page + '&take' + filter.take +
      '&sortRead=' + filter.sortRead);
  }

  getMessageInGroups(filter): Observable<any> {
    return this.http.get(API.messageInGroup + filter.id + '?take=' + filter.take + '&page=' + filter.currentPage);
  }

  sendMessage(id, newMessage): Observable<any> {
    return this.http.post(API.saveMessage, {messageGroupId: id, content: newMessage});
  }

  createGroup(body): Observable<any>{
    return this.http.post(API.messageInGroup, body);
  }

}
