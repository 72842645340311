import {Injectable} from '@angular/core';
import {HttpResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {LoginService} from '../../login.service';
import {Observable, BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RequestHandlerService implements HttpInterceptor {

  isRefreshingToken = false;
  public requests: HttpRequest<any>[] = [];
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private loginService: LoginService, private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request: HttpRequest<any>;
    if (req.url.includes('signIn') || req.url.includes('signup')) {
      request = req;
    } else {
      request = req;
      const token = localStorage.getItem('token');
      if (token) {
        request = req.clone({
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': environment.BASE_URL + environment.API_ENDPOINT,
            'x-access-token': token
          })
        });
      } else {
        request = req.clone({
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': environment.BASE_URL + environment.API_ENDPOINT
          })
        });
      }
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body && (event.body.statusCode === 419 || event.body.statusCode === 401)) {
            this.logoutUser();
            return event;
          } else {
            return event;
          }
        } else {
          return event;
        }
      }));
  }

  handle401Error = (request: HttpRequest<any>, next: HttpHandler) => {
    this.isRefreshingToken = true;
    return this.refreshToken(localStorage.getItem('token')).subscribe((success: any) => {
      if (success.data.token) {
        localStorage.setItem('token', success.data.token);
        this.isRefreshingToken = false;
        return next.handle(this.addToken(request, success.data.token));
      } else {
        this.isRefreshingToken = false;
        this.logoutUser();
      }
    }, error => {
      this.isRefreshingToken = false;
      return this.logoutUser();
    });
  };

  refreshToken(refreshToken: string = null): Observable<any> {
    return this.loginService.refreshToken({
      headers: {
        'x-access-token': refreshToken,
      }
    });
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({setHeaders: {'x-access-token': token}});
  }

  logoutUser = () => {
    localStorage.clear();
    this.router.navigate(['login']);
  };

}
