import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-model-video-gif-view',
  templateUrl: './model-video-gif-view.component.html',
  styleUrls: ['./model-video-gif-view.component.scss']
})
export class ModelVideoGifViewComponent implements OnInit {

  @Input() video: any;
  private preview: any;

  constructor() {
  }

  ngOnInit(): void {
  }

  loadPreview(video): void {
    if (video.notSubscribe) {
      return;
    }
    if (video.fileGIFPath && video.fileGIFPath.includes('https')) {
      video.previewUrl = video.fileGIFPath;
    } else {
      this.preview = setInterval(() => {
        if (video.previewIndex < video.thumbs.length - 1) {
          video.previewIndex++;
        } else {
          video.previewIndex = 0;
        }
      }, 1000);
    }
  }

  stopPreview(video, url): void {
    if (video.fileGIFPath && video.fileGIFPath.includes('https')) {
      video.previewUrl = url;
    } else {
      clearInterval(this.preview);
    }
  }

}
