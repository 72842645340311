import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API} from '../utils/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class AdminVideoListService {

  constructor(private http: HttpClient) {
  }

  getVideoList(data): Observable<any> {
    return this.http.get(API.modelVideoList + '?keyword=' + data.keyword + '&limit=' + data.limit + '&offset=' + data.offset +
      '&order=' + data.order + '&sort=' + data.sort + '&type=' + data.type);
  }

  getVideoCount(data): Observable<any> {
    return this.http.get(API.modelVideoList + '?keyword=' + data.keyword +
      '&order=' + data.order + '&sort=' + data.sort + '&type=' + data.type);
  }
  getModelDetails(): Observable<any> {
    return this.http.get(API.modelDropdown );
  }
  getFTPOption(): Observable<any>{
    return  this.http.get(API.getFTPOptions);
  }

  getTags(): Observable<any> {
    return this.http.get(API.modelTags);
  }

  uploadVideo(data: FormData): Observable<any> {
    return this.http.post(API.modelRelatedVideo, data);
  }

  deleteVideo(id): Observable<any> {
    return this.http.delete(API.modelRelatedVideo + id);
  }
 }
