<div class="example-full-width model_image" (mouseenter)="loadPreview(video)"
     (mouseleave)="stopPreview(video, video?.thumbs[video.previewIndex])">
  <img *ngIf="video.notSubscribe" mat-card-image class="orginal-img"
       [src]='video.notSubscribe | checkImage'>
  <img *ngIf="video.notSubscribe" class="image-background" [src]='video.notSubscribe | checkImage'>
  <img *ngIf="!video.notSubscribe" mat-card-image class="orginal-img"
       [src]="video.previewUrl || video?.thumbs[video.previewIndex] | checkImage" alt="{{video?.name}}">
  <img *ngIf="!video.notSubscribe" class="image-background"
       [src]="video?.thumbs[video.previewIndex] | checkImage" alt="{{video?.name}}">
</div>
