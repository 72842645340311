import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {API} from '../utils/constants/constant';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminDashboardService {

  constructor(private http: HttpClient) {
  }

  getTotalMoviesUploaded(): Observable<any> {
    return this.http.get(API.modelRelatedVideo);
  }

  getNoOfModels(): Observable<any> {
    return this.http.get(API.modelsCount);
  }

  getTotalValueOfOrders(): Observable<any> {
    return this.http.get(API.ordersCount);
  }

  getTotalNoOfUser(): Observable<any> {
    return this.http.get(API.usersCount);
  }

  getTotalNoOfActiveProducts(): Observable<any> {
    return this.http.get(API.productCount);
  }

  getTotalNoOfActiveAlbum(): Observable<any> {
    return this.http.get(API.albumCount);
  }

  getEarningsList(data): Observable<any> {
    return this.http.get(API.earningList + '?take=' + data.take + '&sort=' + data.sort + '&page=' + data.page
    + '&performerId=' + data.performerId + '&userId=' + data.userId + '&dateFrom=' + data.dateFrom + '&dateTo=' + data.dateTo
    + '&order=' + data.order + '&type=' + data.type);
  }

}
