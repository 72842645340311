import {Component, ElementRef, Inject, Injector, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {ROUTES, ROUTESAdmin, ROUTESUser} from '../../../components/models/sidebar-items';
import {AppComponentBase} from '../../../shared/AppComponentBase';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends AppComponentBase implements OnInit {

  public sidebarItems: any[];
  showMenu = '';
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  headerHeight = 60;
  public role: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private inject: Injector
  ) {
    super(inject);
    this.role = this.userHomeService.modelLogin.subscribe(res => {
      if (res && res.status && res.role === 'performer') {
        this.sidebarItems = ROUTES;
      } else if (res && res.status && res.role === 'user') {
        this.sidebarItems = ROUTESUser;
      } else if (res && res.status && res.role === 'admin') {
        this.sidebarItems = ROUTESAdmin;
      }
    });
  }

  initLeftSidebar = () => {
    const _this = this;
    _this.setMenuHeight();
    _this.checkStatuForResize();
  };
  setMenuHeight = () => {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  };
  checkStatuForResize = () => {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  };

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    this.showMenu = window.location.pathname;
    if (this.role === 'performer') {
      this.sidebarItems = ROUTES;
    } else if (this.role === 'admin') {
      this.sidebarItems = ROUTESAdmin;
    } else if (this.role === 'user') {
      this.sidebarItems = ROUTESUser;
    } else {
      this.sidebarItems = [];
    }
    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }

  navigatePath(path): void {
    if (path === 'logout') {
      this.authService.logout();
    } else {
      this.router.navigate([path + JSON.parse(localStorage.getItem('performerDetail')).username]);
    }
  }

  callMenuToggle = (event: any, element: any) => {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
    const hasClass = event.target.classList.contains('toggled');
    if (hasClass) {
      this.renderer.removeClass(event.target, 'toggled');
    } else {
      this.renderer.addClass(event.target, 'toggled');
    }
  };

  mouseHover = (e) => {
    const body = this.elementRef.nativeElement.closest('body');

    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  };

  mouseOut = (e) => {
    const body = this.elementRef.nativeElement.closest('body');

    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  };

  closeSidebar(): void {
    const element = document.getElementById('appBody');
    element.classList.add('menu-expanded');
  }

  toggleMobSidebar(): void {
    const  sidebar = document.getElementById('sidebar-layout');
    sidebar.classList.remove('expanded');
  }

}
