import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {API} from '../utils/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class ModelHomeService {
  public performerProfile: string;
  public performerCover: string;
  public performerId: string;
  public performerProfileListener = new BehaviorSubject(false);
  public performerCoverListener = new BehaviorSubject(false);

  constructor(private http: HttpClient) {
  }

  getRelatedVideo(data): Observable<any> {
    if (data.isMe) {
      return this.http.get(API.modelRelatedVideo + data.video + '/related' + '?performerId=' + data.performer +
        '&test=test&id=' + data.performer);
    }
    return this.http.get(API.modelRelatedVideo + data.video + '/related' + '?performerId=' + data.performer);
  }

  getProductServices(): Observable<any> {
    return this.http.get(API.modelProductCatagories);
  }

  addFavourite(data): Observable<any> {
    return this.http.post(API.modelSaveVideo + '?userId=' + data.userId + '&videoId=' + data.videoId + '&type=' + data.type, data.userId);
  }

  getPlayVideoService(data): Observable<any> {
    return this.http.get(API.modelRelatedVideo + data.id);
  }

  getCommentService(data): Observable<any> {
    return this.http.get(API.getComments + '?page=' + data.page + '&take=' + data.take + '&videoId=' + data.videoId);
  }

  sendCommentService(data, type): Observable<any> {
    return this.http.post(API.sendComment + '?type=' + type, data);
  }

  deleteComentService(id): Observable<any> {
    return this.http.delete(API.sendComment + id);
  }

  checkSubscriptionService(id): Observable<any> {
    return this.http.get(API.checkSubscriptions + id);
  }

  checkSaleVideoBuy(id): Observable<any> {
    return this.http.get(API.modelRelatedVideo + id + '/checkBuy');
  }

  getTagVideo(data): Observable<any> {
    return this.http.get(API.modelSearchVideo + '?page=' + data.page + '&performerId=' + data.performerId + '&keyword=' + data.keyword +
      '&take=' + data.take + '&type=' + data.type + '&categoryId=' + data.categoryId);
  }

  getPerformerByName(name): Observable<any> {
    return this.http.get(API.performerByName + '?name=' + name);
  }

  updateViewCount(performerId): Observable<any> {
    return this.http.put(API.addPerformerViewCount + '?id=' + performerId, {});
  }
}
