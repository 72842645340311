<nav class="main-menu">
  <div class="top-sidebar-icon">
  <div class="app-logo">
    <img class="logo-img" src="assets\images\Logo.jpg" title="Logo"/>
  </div>
  <i class="fa fa-times close-icon" aria-hidden="false" (click)="toggleMobSidebar()"></i>
  </div>
  <ul>
    <li *ngFor="let sidebarItem of sidebarItems" class="has-subnav"
        [class.active]="showMenu === sidebarItem.path"
        [ngClass]="{'activeClass': showMenu.includes(sidebarItem.path)}">
      <a [routerLink]="sidebarItem.path"
         (click)="callMenuToggle($event, sidebarItem.path); toggleMobSidebar()">
        <i class="fa-2x" [ngClass]="[sidebarItem.icon]"></i>
        <span class="nav-text">{{ sidebarItem.title }} </span>
      </a>
    </li>
  </ul>
</nav>
