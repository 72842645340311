<div class="header-wrapper">
  <div class="left-sec">
    <div class="menu-actions" (click)="toggleMobSidebar()">
      <i
        class="fa fa-bars sidebar-open"
        aria-hidden="false"
      ></i>
    </div>
    <div class="mail-icon" *ngIf="role!=='admin'">
      <i class="material-icons" *ngIf="role!=='user'" (click)="navigatePath('model/home')"
         matTooltip="{{'home' | translate}}">home</i>
      <i class="material-icons" *ngIf="role !=='user'" (click)="navigatePath('model/messages')"
         matTooltip="{{'message' | translate}}">forum</i>
      <i class="far fa-address-card" *ngIf="role=='user'" (click)="navigatePath('user/contact')"
         matTooltip="{{'Contact' | translate}}"></i>
      <i class="icon-bubbles" *ngIf="role=='user'" (click)="navigatePath('user/chat/messages')"
         matTooltip="{{'Messages' | translate}}"></i>
      <i [matBadge]="totalCard" class="icon-basket-loaded" *ngIf="role=='user'" matBadgeColor="warn" (click)="navigatePath('user/cart')"
         matTooltip="{{'Cart' | translate}}"></i>
      <i matBadge="{{totalAlert}}" class="fas fa-bell bell-ring fd-fix"  matBadgeColor="warn" *ngIf="(role =='performer' || role =='user') && totalAlert" mat-icon-button
         [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" (click)="menuTrigger.openMenu()"
         matTooltip="{{'notifications' | translate}}">
      </i>
      <mat-menu #menu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item (click)="clickNotification('inbox')" *ngIf="alert && alert.inbox">
          <span>New message ({{alert.inbox}})</span>
        </button>
        <button mat-menu-item *ngIf="role === 'performer' && alert && alert.subscriber" (click)="clickNotification('subscriber')">
          <span>New subscriber ({{alert.subscriber}})</span>
        </button>
        <button mat-menu-item *ngIf="role === 'user' && alert && alert.product" (click)="clickNotification('product')">
          <span>New Product ({{alert.product}})</span>
        </button>
        <button mat-menu-item *ngIf="role === 'user' && alert && alert.photo" (click)="clickNotification('photo')">
          <span>New Photo ({{alert.photo}})</span>
        </button>
        <button mat-menu-item *ngIf="role === 'user' && alert && alert.video" (click)="clickNotification('video')">
          <span>New Video({{alert.video}})</span>
        </button>
        <button mat-menu-item *ngIf="role === 'user' && alert && alert.saleVideo" (click)="clickNotification('saleVideo')">
          <span>New saleVideo({{alert.saleVideo}})</span>
        </button>
        <button mat-menu-item *ngIf="role === 'performer' && alert && alert.earning" (click)="clickNotification('earning')">
          <span>New earnings ({{alert.earning}})</span>
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="right-sec">
    <button *ngIf="email && (role === 'user' || role === 'performer')" class="upload-btn upload-first-btn" mat-raised-button (click)="loginToAdmin()"
            color="accent" style="margin-right: 10px;z-index: 100">{{'backToAdmin'|translate}}</button>
    <mat-icon *ngIf="role === 'user' && (userDetail.sex === 'female' || userDetail.sex === 'Female') && !userDetail.convertToModel" (click)="convertAsModel()"
              style="cursor: pointer;z-index: 100"
              class="convert" matTooltip="{{'convertAsModel' | translate}}">compare_arrows
    </mat-icon>
    <mat-icon *ngIf="role === 'performer'  && !performerDetail.convertToUser" (click)="convertAsUser()"
              style="cursor: pointer;z-index: 100"
              class="convert" matTooltip="{{'convertAsUser' | translate}}">compare_arrows
    </mat-icon>
    <i class="fas fa-sign-out-alt material-icons sign-out-icon" matTooltip="{{'signOut' | translate}}"
       (click)="navigatePath('logout')"></i>
    <img
      [src]="avatar|checkAvatar"
      class="rounded-circle" width="32" height="32" alt="User" matTooltip="{{userName | translate}}"/>
  </div>
</div>
