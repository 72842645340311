import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'checkImage'})
export class CheckImage implements PipeTransform {
  constructor() {
  }

  transform(url, type?): any {
    if ((!url || url === 'null') && type !== 'cover') {
      return '/assets/images/noImageThumb1.jpg';
    }
    if ((!url || url === 'null') && type === 'cover') {
      return '/assets/images/noImageFull.jpg';
    }
    return url;
  }
}
