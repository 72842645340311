import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API} from '../utils/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class PhotoManagerService {

  constructor(private http: HttpClient) { }

  getPhotoManagerList(data): Observable<any>{
    return this.http.get(API.photoList + '?page=' + data.page + '&performerId=' + data.performerId + '&take=' + data.take
    + '&sort=' + data.sort + '&order=' + data.order + '&keyword=' + data.keyword);
  }
  addPhotoManager(data): Observable<any>{
    return this.http.post(API.addPhoto, data);
  }
  bulkUploadPhoto(data): Observable<any>{
    return this.http.post(API.bulkUpload, data);
  }
  deletePhoto(id): Observable<any>{
    return this.http.delete(API.deletePhoto + '?id=' + id);
  }
  getPhotoDetail(id): Observable<any>{
    return this.http.get(API.getPhoto + '?id=' + id);
  }
  updatePhoto(id, data): Observable<any>{
    return this.http.put(API.updatePhoto + id, data);
  }

}
