import {FormBuilder} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Injector} from '@angular/core';
import {Router} from '@angular/router';
import {LoginService} from '../services/login.service';
import {RegisterService} from '../services/register.service';
import {AuthService} from '../services/utils/AuthGaurd/auth.service';
import {CalenderService} from '../services/calender.service';
import {ToastrService} from 'ngx-toastr';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ModelService} from '../services/performer/model.service';
import {ModelTaggedVideoService} from '../services/performer/model-tagged-video.service';
import {ModelCatagoriesService} from '../services/performer/model-catagories.service';
import {StatisticsService} from '../services/statistics.service';
import {ModelPerformerDetailService} from '../services/performer/model-performer-detail.service';
import {ProductService} from '../services/performer/product.service';
import {MessageService} from '../services/message.service';
import {ModelPayoutRequestService} from '../services/performer/model-payout-request.service';
import {EarningService} from '../services/earning.service';
import {ModelAlbumsService} from '../services/performer/model-albums.service';
import {ModelHomeService} from '../services/performer/model-home.service';
import {AllMoviesServiceService} from '../services/performer/all-movies-service.service';
import {NotificationService} from '../services/notification.service';
import {VideoManagerService} from '../services/video-manager.service';
import {UpdateProfileService} from '../services/update-profile.service';
import {PhotoManagerService} from '../services/performer/photo-manager.service';
import {PayoutRequestService} from '../services/admin/payout-request.service';
import {AdminModelService} from '../services/admin/admin-model.service';
import {AdminSettingsService} from '../services/admin/admin-setting.service';
import {AdminDashboardService} from '../services/admin/admin-dashboard.service';
import {AdminUserService} from '../services/admin/admin-user.service';
import {AdminProductCategoriesService} from '../services/admin/admin-product-categories.service';
import {AdminOrdersService} from '../services/admin/admin-orders.service';
import {AdminVideoListService} from '../services/admin/admin-video-list.service';
import {SocketService} from '../services/socket.service';
import {ActivatedRoute} from '@angular/router';
import {AdminPhotoService} from '../services/admin/admin-photo.service';
import {PaymentServiceService} from '../services/payment-service.service';
import {AdminCouponsService} from '../services/admin/admin-coupons.service';
import {AdminSubscribersService} from '../services/admin/admin-subscribers.service';
import {AdminStoreService} from '../services/admin/admin-store.service';
import {AdminPagelistService} from '../services/admin/admin-pagelist.service';
import {AdminVideoCategoryService} from '../services/admin/admin-video-category.service';
import {AdminBannerService} from '../services/admin/admin-banner.service';
import {UserHomeService} from '../services/user/user-home.service';
import {AdminEarningStatsService} from '../services/admin/admin-earning-stats.service';
import {UserIpService} from '../services/admin/user-ip.service';

export abstract class AppComponentBase {
  public adminPagelistService : AdminPagelistService;
  public fb: FormBuilder;
  public dialog: MatDialog;
  public router: Router;
  public loginService: LoginService;
  public registerService: RegisterService;
  public authService: AuthService;
  public calenderService: CalenderService;
  public toastr: ToastrService;
  public loader: NgxUiLoaderService;
  public modelService: ModelService;
  public modelTaggedVideoService: ModelTaggedVideoService;
  public modelCatagoriesService: ModelCatagoriesService;
  public statisticsService: StatisticsService;
  public modelPerformerDetailService: ModelPerformerDetailService;
  public productService: ProductService;
  public messageService: MessageService;
  public modelPayoutRequestService: ModelPayoutRequestService;
  public earningService: EarningService;
  public movieService: AllMoviesServiceService;
  public photoService: PhotoManagerService;
  public getModelVideoListService: VideoManagerService;
  public performerDetail: any;
  public modelAlbums: ModelAlbumsService;
  public modelHomeService: ModelHomeService;
  public role: string;
  public notificationService: NotificationService;
  public modelVideoListService: VideoManagerService;
  public videoManagerService: VideoManagerService;
  public photoManagerService: PhotoManagerService;
  public updateProfileService: UpdateProfileService;
  public pageSizeOptions = [10, 20, 50, 100];
  public payoutRequestService: PayoutRequestService;
  public adminModelService: AdminModelService;
  public adminUserService: AdminUserService;
  public adminOrdersService: AdminOrdersService;
  public adminProductCategoriesService: AdminProductCategoriesService;
  public adminVideoListService: AdminVideoListService;
  public sktService: SocketService;
  public adminSettingServices: AdminSettingsService;
  public route: ActivatedRoute;
  public adminPhotoService: AdminPhotoService;
  public adminDashboardService: AdminDashboardService;
  public paymentService: PaymentServiceService;
  public adminCouponsService: AdminCouponsService;
  public adminSubscriberService: AdminSubscribersService;
  public adminStoreService: AdminStoreService;
  public adminVideoCategoryService: AdminVideoCategoryService;
  public adminBannerService: AdminBannerService;
  public userHomeService: UserHomeService;
  public adminEarningStatsService: AdminEarningStatsService;
  public usersIpService: UserIpService;
  public maxSearchLength = 30;
  public phoneRegex: RegExp = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
  public emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public pwdPattern: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}/;

  protected constructor(injector: Injector) {
    this.fb = injector.get(FormBuilder);
    this.dialog = injector.get(MatDialog);
    this.router = injector.get(Router);
    this.loginService = injector.get(LoginService);
    this.registerService = injector.get(RegisterService);
    this.authService = injector.get(AuthService);
    this.calenderService = injector.get(CalenderService);
    this.toastr = injector.get(ToastrService);
    this.loader = injector.get(NgxUiLoaderService);
    this.modelService = injector.get(ModelService);
    this.modelTaggedVideoService = injector.get(ModelTaggedVideoService);
    this.modelCatagoriesService = injector.get(ModelCatagoriesService);
    this.statisticsService = injector.get(StatisticsService);
    this.modelPerformerDetailService = injector.get(ModelPerformerDetailService);
    this.productService = injector.get(ProductService);
    this.messageService = injector.get(MessageService);
    this.modelPayoutRequestService = injector.get(ModelPayoutRequestService);
    this.earningService = injector.get(EarningService);
    this.modelAlbums = injector.get(ModelAlbumsService);
    this.modelHomeService = injector.get(ModelHomeService);
    this.performerDetail = JSON.parse(localStorage.getItem('performerDetail'));
    this.role = localStorage.getItem('role');
    this.movieService = injector.get(AllMoviesServiceService);
    this.notificationService = injector.get(NotificationService);
    this.modelVideoListService = injector.get(VideoManagerService);
    this.videoManagerService = injector.get(VideoManagerService);
    this.photoManagerService = injector.get(PhotoManagerService);
    this.updateProfileService = injector.get(UpdateProfileService);
    this.getModelVideoListService = injector.get(VideoManagerService);
    this.photoService = injector.get(PhotoManagerService);
    this.payoutRequestService = injector.get(PayoutRequestService);
    this.adminModelService = injector.get(AdminModelService);
    this.adminUserService = injector.get(AdminUserService);
    this.adminOrdersService = injector.get(AdminOrdersService);
    this.adminProductCategoriesService = injector.get(AdminProductCategoriesService);
    this.adminVideoListService = injector.get(AdminVideoListService);
    this.sktService = injector.get(SocketService);
    this.adminSettingServices = injector.get(AdminSettingsService);
    this.route = injector.get(ActivatedRoute);
    this.adminPhotoService = injector.get(AdminPhotoService);
    this.adminDashboardService = injector.get(AdminDashboardService);
    this.paymentService = injector.get(PaymentServiceService);
    this.adminCouponsService = injector.get(AdminCouponsService);
    this.adminSubscriberService = injector.get(AdminSubscribersService);
    this.adminStoreService = injector.get(AdminStoreService);
    this.adminPagelistService = injector.get(AdminPagelistService);
    this.adminVideoCategoryService = injector.get(AdminVideoCategoryService);
    this.adminBannerService = injector.get(AdminBannerService);
    this.userHomeService = injector.get(UserHomeService);
    this.adminEarningStatsService = injector.get(AdminEarningStatsService);
    this.usersIpService = injector.get(UserIpService);
  }

  initFreshChat(): void {
    const self = this;
    (window as any).fcWidget.init({
      token: 'f5d66a71-fd39-4c41-9d97-fcb3004849c3',
      host: 'https://wchat.in.freshchat.com'
    });
    (window as any).fcWidget.on('widget:opened', (resp) => {
      (window as any).fcWidget.user.get((user) => {
        if (user.data) {
          (window as any).fcWidget.user.update({
            firstName: self.performerDetail.name || self.performerDetail.username,
            email: self.performerDetail.email,
            meta: {
              currentPage: window.location.pathname
            }
          });
        } else {
          (window as any).fcWidget.user.create({
            firstName: self.performerDetail.name || self.performerDetail.username,
            email: self.performerDetail.email,
            meta: {
              currentPage: window.location.pathname
            }
          });
        }
      });
    });
  }

  initialize(i, t): void {
    let e;
    i.getElementById(t) ? this.initFreshChat() : ((e = i.createElement('script')).id = t, e.async = !0, e.src = 'https://wchat.in.freshchat.com/js/widget.js', e.onload = this.initFreshChat, i.head.appendChild(e));
  }

  initiateCall(): void {
    this.initialize(document, 'freshchat-js-sdk');
  }

}
