import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {API} from '../utils/constants/constant';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserIpService {

  constructor(private http: HttpClient) {
  }

  getUserIpList(filter): Observable<any> {

    return this.http.get(API.userIpList + '?id=' + filter.userId + '&keyword=' + filter.keyword + '&page=' +
      filter.page + '&limit=' + filter.take + '&order=' + filter.order + '&sort=' +
      filter.sort + '&offset=' + filter.offset + '&event=' + filter.event);
  }

}
