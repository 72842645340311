import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API} from '../utils/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class ModelCatagoriesService {

  constructor(private http: HttpClient) {
  }

  getCatagoriesList(): Observable<any>{
   return this.http.get(API.modelCatagories);
  }

}
