import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({name: 'checkAvatar'})
export class CheckAvatar implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(url): any {
    if (!url) {
      return 'assets/images/default.jpg';
    }
    return url;
  }
}
