import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API} from './utils/constants/constant';
import {date} from 'ngx-custom-validators/src/app/date/validator';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(private http: HttpClient) {
  }

  getProfileViewStats(dates): Observable<any> {
    return this.http.get(API.profileViewStats + '?endDate=' + dates.endDate + '&startDate=' + dates.startDate);
  }

  getCommentsStats(dates): Observable<any> {
    return this.http.get(API.commentStats + '?endDate=' + dates.endDate + '&startDate=' + dates.startDate);
  }

  getLikeStats(dates): Observable<any> {
    return this.http.get(API.likeStats + '?endDate=' + dates.endDate + '&startDate=' + dates.startDate);
  }

  getSubscriberStats(dates): Observable<any> {
    return this.http.get(API.subscriberStats + '?endDate=' + dates.endDate + '&startDate=' + dates.startDate);
  }

  getTopVideos(filter): Observable<any> {
    return this.http.get(API.topVideo + '?page=' + filter.page + '&performerId=' + filter.performerId + '&take=' + filter.take);
  }
}
