import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {API} from '../utils/constants/constant';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminModelService {

  constructor(private http: HttpClient) {
  }

  getModelList(filter): Observable<any> {
    return this.http.get(API.modelList + '?keyword=' + filter.keyword + '&page=' +
      filter.page + '&take=' + filter.take + '&order=' + filter.order + '&sort=' + filter.sort + '&event=' + filter.event);
  }

  updateModelSort(data): Observable<any> {
    return this.http.put(API.updatePerformer + data._id, data);
  }
  deleteModel(id): Observable<any> {
    return this.http.delete(API.DeleteModel + id);
  }
  deleteRequestModel(id, role): Observable<any> {
    return this.http.delete(API.deleteRequestModel + id + '?role=' + role);
  }
  modelDetails(id): Observable<any> {
    return this.http.get(API.getModelDetails + id);
  }
  exportSubscribes(id): Observable<any> {
    return this.http.get(API.exportSubscribersCsv + id);
   }
  exportPerformers(): Observable<any> {
    return this.http.get(API.exportPerformerCsv );
   }
  updateModelData(id , data): Observable<any> {
    return this.http.put(API.updatePerformerDetails + id, data);
  }
  updateModelDataWithoutFile(id , data): Observable<any> {
    return this.http.put(API.updatePerformerDetails + id, data);
  }
  createModel(data): Observable<any> {
    return this.http.post(API.createModel, data);
  }
  getImage(id): Observable<any> {
    return this.http.get(API.getImage + id);
  }
  loginAsModel(data): Observable<any> {
    return this.http.post(API.loginAsModel, data);
  }
}
