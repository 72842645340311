import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API} from './utils/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class PaymentServiceService {

  constructor(private http: HttpClient) {
  }

  getSetting(): Observable<any> {
    return this.http.get(API.setting);
  }
  getPerformer(id: string): Observable<any> {
    return this.http.get(API.getPerformer + '/' + id);
  }
  doDirectPayment(data: any): Observable<any> {
    const param = data.userId ? 'signup' : 'payment';
    return this.http.post(API.paypal + param, data);
  }
  callback(data): Observable<any> {
    return this.http.post(API.paypalCallback , data);
  }
  getVideo(id: string): Observable<any> {
    return this.http.get(API.modelRelatedVideo + id);
  }
  cancelSubscription(data): Observable<any> {
    return this.http.post(API.paypalCancel , data);
  }
  getCoupon(data): Observable<any> {
    return this.http.get(API.coupon + '?code=' +  data );
  }
  createTransaction(payment: any): Observable<any> {
    return this.http.post(API.creditDebitCard, payment);
  }
}
