import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API} from './utils/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class EarningService {

  constructor(private http: HttpClient) {
  }

  getEarningStats(date): Observable<any> {
    return this.http.get(API.earningStats + '?dateFrom=' + date.from + '&dateTo=' + date.to);
  }

  getEarningList(filter): Observable<any> {
      return this.http.get(API.earningList + '?dateFrom=' + filter.from + '&dateTo=' + filter.to + '&take=' + filter.take +
        '&page=' + filter.page + '&type=' + filter.type);
  }
}
