import {AfterViewInit, Component, ElementRef, Injector, OnDestroy, OnInit} from '@angular/core';
import {AppComponentBase} from '../../../shared/AppComponentBase';
import {HeaderRoute} from '../../../services/utils/constants/constant';
import {EStatusCode} from '../../../services/utils/constants/response-handler';
import {ISubscription} from 'rxjs-compat/Subscription';
import {CommonDialogModelComponent} from '../../../shared/common-dialog-model/common-dialog-model.component';
import {environment} from '../../../../environments/environment.prod';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends AppComponentBase implements OnInit, OnDestroy, AfterViewInit {

  public connected: boolean;
  public totalAlert = 0;
  public alert = {
    inbox: 0,
    subscriber: 0,
    video: 0,
    saleVideo: 0,
    post: 0,
    tip: 0,
    photo: 0,
    product: 0,
    earning: 0,
    userId: ''
  };
  public avatar: string;
  public headerItems: any;
  public drop: boolean;
  public performerId: string;
  public totalCard = 0;
  public cardItem = [];
  private cardSubscription: ISubscription;
  private profileImageSubscription: ISubscription;
  private convertToModel: ISubscription;
  private convertToUser: ISubscription;
  private notificationSubscription: ISubscription;
  private modelLoginSubscription: ISubscription;
  public userId: string;
  public userDetail: any;
  public email = '';
  public password: any;
  public role: any;
  public userName: any;

  constructor(private inject: Injector, private elementRef: ElementRef) {
    super(inject);
    this.cardSubscription = this.productService.cardBuyEvent.subscribe(res => {
      if (res) {
        this.getCardCount();
        this.productService.cardBuyEvent.next(false);
      }
    });
    this.profileImageSubscription = this.userHomeService.changeProfileImage.subscribe(res => {
      if (res && res.status) {
        this.avatar = res.url;
        this.userHomeService.changeProfileImage.next(null);
      }
    });
    this.convertToModel = this.userHomeService.convertToModel.subscribe(res => {
      if (res && res.status) {
        this.userDetail = res.userDetail;
        this.userHomeService.convertToModel.next(null);
      }
    });
    this.convertToUser = this.userHomeService.convertToUser.subscribe(res => {
      if (res && res.status) {
        this.performerDetail = res.performerDetail;
        this.userHomeService.convertToUser.next(null);
      }
    });
    this.notificationSubscription = this.notificationService.notificationListener.subscribe(res => {
      if (res) {
        this.getNotificationAlert();
        this.notificationService.notificationListener.next(false);
      }
    });
    this.modelLoginSubscription = this.userHomeService.modelLogin.subscribe(res => {
      if (res && res.status && res.role) {
        this.role = res.role;
        this.initializeVariables();
        this.userHomeService.modelLogin.next(null);
      }
    });
  }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    this.initializeVariables();
  }

  ngAfterViewInit(): void {
    if (this.role !== 'admin') {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = 'https://www.paypal.com/sdk/js?client-id=' + environment.CLIENT_ID + '&intent=capture&currency=USD&vault=true';
      this.elementRef.nativeElement.appendChild(s);
    }
  }

  initializeVariables(): void {
    this.headerItems = HeaderRoute;
    this.connected = !!this.sktService.connectToServer();
    this.email = localStorage.getItem('email') ? localStorage.getItem('email') : '';
    this.password = localStorage.getItem('password');
    if (this.role === 'performer') {
      this.performerDetail = JSON.parse(localStorage.getItem('performerDetail'));
      this.avatar = localStorage.getItem('performerDetail') ? JSON.parse(localStorage.getItem('performerDetail')).imageThumbPath : '';
      this.performerId = localStorage.getItem('performerId');
      this.userName = localStorage.getItem(('performerDetail')) ? JSON.parse(localStorage.getItem('performerDetail')).name : '';
    } else if (this.role === 'user') {
      this.avatar = localStorage.getItem('userDetail') ? JSON.parse(localStorage.getItem('userDetail')).imageThumbPath : '';
      this.userId = localStorage.getItem('userId');
      this.userDetail = JSON.parse(localStorage.getItem('userDetail'));
      this.userName = localStorage.getItem(('userDetail')) ? JSON.parse(localStorage.getItem('userDetail')).name : '';
    } else {
      this.userName = localStorage.getItem(('adminDetail')) ? JSON.parse(localStorage.getItem('adminDetail')).name : '';
      this.avatar = localStorage.getItem('adminDetail') ? JSON.parse(localStorage.getItem('adminDetail')).imageThumbPath : '';
    }
    this.sktService.on('NOTIFICATIONS').subscribe(async data => {
      this.alert = data.data.data;
      this.totalAlert = this.alert.inbox + this.alert.subscriber + this.alert.post +
        this.alert.video + this.alert.saleVideo + this.alert.product + this.alert.photo + this.alert.earning;
    });
    if (this.role !== 'admin') {
      setInterval(() => {
        this.getNotificationAlert();
      }, 30000);
    }
    this.getCardCount();
  }

  ngOnDestroy(): void {
    this.cardSubscription.unsubscribe();
    if (this.profileImageSubscription) {
      this.profileImageSubscription.unsubscribe();
    }
    this.profileImageSubscription.unsubscribe();
    this.convertToModel.unsubscribe();
    this.convertToUser.unsubscribe();
    this.notificationSubscription.unsubscribe();
    this.modelLoginSubscription.unsubscribe();
  }

  getNotificationAlert(): void {
    this.notificationService.getNotificationAlert().subscribe(res => {
      if (res.statusCode === EStatusCode.OK) {
        this.alert = res.data;
        this.totalAlert = this.alert.inbox + this.alert.subscriber + this.alert.post +
          this.alert.video + this.alert.saleVideo + this.alert.product + this.alert.photo + this.alert.earning;
      } else {
        this.totalAlert = 0;
      }
    }, error => {
      this.totalAlert = 0;
    });
  }

  navigatePath(path): void {
    if (path === 'logout') {
      this.authService.logout();
      this.sktService.disconnect();
    } else {
      this.router.navigate([path]);
    }
  }

  clickNotification(type): void {
    this.notificationService.readAlert(type).subscribe((res: any) => {
      if (type === 'inbox') {
        this.alert.inbox = 0;
      }
      if (type === 'photo') {
        this.alert.photo = 0;
      }
      if (type === 'product') {
        this.alert.product = 0;
      }
      if (type === 'subscriber') {
        this.alert.subscriber = 0;
      }
      if (type === 'earning') {
        this.alert.earning = 0;
      }
      if (type === 'video') {
        this.alert.inbox = 0;
      }
      if (type === 'saleVideo') {
        this.alert.inbox = 0;
      }
      if (this.role === 'performer') {
        this.router.navigate(['model/notifications'], {queryParams: {type}});
      } else {
        this.navigatePath('user/notifications');
      }
    });
  }

  getCardCount(): void {
    this.cardItem = JSON.parse(localStorage.getItem('cardProduct'));
    if (this.cardItem && this.cardItem.length) {
      this.totalCard = this.cardItem.length;
      this.productService.cardBuyEvent.next(false);
    } else {
      this.totalCard = 0;
      this.productService.cardBuyEvent.next(false);
    }
  }

  convertAsModel(): void {
    const dialog = this.dialog.open(CommonDialogModelComponent, {
      data: {action: 'userToModel', role: 'user'},
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        const dialogRef = this.dialog.open(CommonDialogModelComponent, {
          data: {action: 'userId'},
        });
        dialogRef.afterClosed().subscribe((response) => {
          if (response) {
            this.loader.start();
            this.adminUserService.convertAsModel(this.userId).subscribe(res => {
              this.loader.stop();
              if (res.statusCode === EStatusCode.OK) {
                this.toastr.success(res.message);
                this.userDetail = JSON.parse(localStorage.getItem('userDetail'));
                this.userDetail.convertToModel = true;
                localStorage.setItem('userDetail', JSON.stringify(this.userDetail));
                this.userHomeService.convertToModel.next({status: true, userDetail: this.userDetail});
              } else {
                this.toastr.error(res.message);
              }
            }, error => {
            });
          }
        });
      }
    });

  }

  loginToAdmin(): void {
    const data = {
      email: this.email,
      password: this.password
    };
    this.loginService.doLogin(data).subscribe(res => {
      if (res.statusCode === EStatusCode.OK) {
        this.email = localStorage.getItem('email');
        this.password = localStorage.getItem('password');
        localStorage.clear();
        localStorage.setItem('email', this.email);
        localStorage.setItem('password', this.password);
        localStorage.setItem('token', res.token);
        localStorage.setItem('role', res.role);
        localStorage.setItem('adminId', res.id);
        localStorage.setItem('adminDetail', JSON.stringify(res.adminDetail));
        this.avatar = localStorage.getItem('adminDetail') ? JSON.parse(localStorage.getItem('adminDetail')).imageThumbPath : '';
        this.userHomeService.modelLogin.next({status: true, role: res.role});
        this.router.navigate(['admin/dashboard']);
      }
    }, err => {
    });
  }

  convertAsUser(): void {
    const dialogRef = this.dialog.open(CommonDialogModelComponent, {
      data: {action: 'modelToUser', role: 'model'},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.adminUserService.convertAsUser(this.performerId).subscribe(res => {
          if (res.statusCode === EStatusCode.OK) {
            this.toastr.success(res.message);
            this.performerDetail = JSON.parse(localStorage.getItem('performerDetail'));
            this.performerDetail.convertToUser = true;
            localStorage.setItem('performerDetail', JSON.stringify(this.performerDetail));
            this.userHomeService.convertToUser.next({status: true, performerDetail: this.performerDetail});
          } else {
            this.toastr.error(res.message);
          }
        }, err => {
        });
      }
    });
  }

  toggleMobSidebar(): void {
    const  sidebar = document.getElementById('sidebar-layout');
    sidebar.classList.add('expanded');
  }

}
