import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {API} from './utils/constants/constant';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public notificationListener = new BehaviorSubject(false);

  constructor(private http: HttpClient) {
  }

  getNotificationList(data): Observable<any> {
    return this.http.get(API.notificationList + '?event=' + data.event + '&page=' + data.page + '&take=' + data.take);
  }

  readAlert(type): Observable<any> {
    return this.http.post(API.readAlert + '?field=' + type, null);
  }

  getNotificationAlert(): Observable<any> {
    return this.http.get(API.notificationAlert);
  }
}
