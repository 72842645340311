import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API} from './utils/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public isUserLoggedIn = new BehaviorSubject(false);

  constructor(private http: HttpClient) {
  }

  refreshToken(header: any): Observable<any> {
    return this.http.get(API.refreshToken, header);
  }

  doLogin(userDetails): Observable<any> {
    return this.http.post(API.login, userDetails);
  }

  getSubscribersService(): Observable<any>{
    return this.http.get(API.getSubscribes);
  }
  forgotPassword(email): Observable<any> {
    return this.http.get(API.forgotPassword + '?email=' + email);
  }

}
