import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API} from '../utils/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class AdminSubscribersService {

  constructor(private http: HttpClient) {
  }

  getSubscribersList(filter): Observable<any> {
    return this.http.get(API.subscriberList + '?page=' + filter.page + '&take=' + filter.take +
      '&performerId=' + filter.performerId + '&order=' + filter.order + '&sort=' + filter.sort + '&keyword=' + filter.keyword);
  }
  getCancelSubscribersList(data): Observable<any> {
    return this.http.get(API.cancelSubscriptions + '?performerId=' + data.performerId + '&userId=' + data.userId +
    '&page=' + data.page + '&order=' + data.order + '&take=' + data.take + '&sort=' + data.sort + '&start=' + data.start +
      '&end=' + data.end);
  }
  getUserDropdownList(): Observable<any> {
    return this.http.get(API.usersDropdown);
  }
  createSubscription(data): Observable<any> {
    return this.http.post(API.createSubscription, data);
  }
}
