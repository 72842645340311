import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API} from './utils/constants/constant';
@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) { }
  createUser(data): Observable<any> {
    return this.http.post(API.registerPage, data);
  }
}
