import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API} from './utils/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class CalenderService {

  constructor(private http: HttpClient) {
  }

  getCalenderEventList(data): Observable<any>{
     return this.http.get(API.calenderEvents + '?page=' + data.page + '&performerId=' + data.performerId +
       '&take=' + data.take + '&order=' + data.order + '&sort=' + data.sort + '&keyword=' + data.keyword);
  }

  getCalenderEvent(id): Observable<any>{
    return this.http.get(API.updateViewDelCalenderEvent + id);
  }

  createCalenderEvent(calenderEventDetails): Observable<any> {
    return this.http.post(API.createCalender, calenderEventDetails);
  }

  deleteCalenderEvent(id): Observable<any> {
    return this.http.delete(API.updateViewDelCalenderEvent + id);
  }

  updateCalenderEvent(id, calenderEventDetails): Observable<any> {
    return this.http.put(API.updateViewDelCalenderEvent + id, calenderEventDetails);
  }

  getCalender(data): Observable<any>{
     return this.http.get(API.createCalender + '?end=' + data.end
       + '&performerId=' + data.performerId + '&start=' + data.start);
 }
}
