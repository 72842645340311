import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {EStatusCode} from '../../../../services/utils/constants/response-handler';
import {AppComponentBase} from '../../../../shared/AppComponentBase';

@Component({
  selector: 'app-model-sale-videos',
  templateUrl: './model-sale-videos.component.html',
  styleUrls: ['./model-sale-videos.component.scss']
})
export class ModelSaleVideosComponent extends AppComponentBase implements OnInit, OnDestroy {
  public selectedSort = 'newest';
  public sorts: string[];
  public totalActualVideos = 0;
  public totalCurrentVideos = 0;
  public performerProfile: string;
  public videosList = [];
  public keyword = '';
  @Input() private performerId: string;
  private preview: any;
  private page = 1;
  private take = 12;
  private performerProfileSubscription: any;
  private userId: any;
  private isMe: boolean;
  private subscribeList = [];

  constructor(private inject: Injector) {
    super(inject);
    this.role = localStorage.getItem('role');
    this.performerProfileSubscription = this.modelHomeService.performerProfileListener.subscribe(res => {
      if (res) {
        this.performerProfile = this.modelHomeService.performerProfile;
        this.performerId = this.modelHomeService.performerId;
        this.isMe = this.userId === this.performerId;
        this.videosList = [];
        this.getSubscribeList();
        this.modelHomeService.performerProfileListener.next(false);
      }
    });
  }

  ngOnInit(): void {
    this.sorts = ['newest', 'oldest', 'mostLike', 'mostView'];
    // this.getSubscribeList();
    if (localStorage.getItem('performerId')) {
      this.userId = localStorage.getItem('performerId');
    } else {
      this.userId = localStorage.getItem('userId');
    }
    this.isMe = this.userId === this.performerId;
    this.performerProfile = this.modelHomeService.performerProfile;
  }

  ngOnDestroy(): void {
    this.performerProfileSubscription.unsubscribe();
  }

  applySearchFilter(): void {
    this.router.navigate(['model/search-video'], { queryParams: {  keyword: this.keyword.trim(), performer: this.performerId }});
  }

  getSaleVideo(): void {
    if (this.performerId) {
      this.modelTaggedVideoService.getVideoList({
        isSaleVideo: true,
        page: this.page,
        performerId: this.performerId,
        status: 'active',
        take: this.take,
        sort: this.selectedSort,
        isMe: this.isMe,
      }).subscribe(res => {
        if (res.statusCode === EStatusCode.OK) {
          if (this.page === 1) {
            this.totalActualVideos = res.count;
          }
          if (res.items.length) {
            res.items.forEach(item => {
              if (item.showPreview && item.thumbs.length || item.thumbs.length && this.isSubscribe(item)  ) {
                item.previewIndex = 0;
              }
              else{
                item.notSubscribe = 'assets/images/subscriber-thumb.png';
              }
              this.videosList.push(item);
            });
            this.totalCurrentVideos = this.videosList.length;
          }
        }
      }, error => {
        console.log(error);
      });
    }
  }

  loadPreview(video): void {
    if (video.notSubscribe){
      return;
    }
    this.preview = setInterval(() => {
      if (video.previewIndex < video.thumbs.length - 1) {
        video.previewIndex++;
      } else {
        video.previewIndex = 0;
      }
    }, 1000);
  }

  stopPreview(): void {
    clearInterval(this.preview);
  }

  loadMore(): void {
    this.page++;
    this.getSaleVideo();
  }

  isSubscribe(item): boolean {
    let bool: boolean;
    (item.performer || []).forEach(p => {
      if (this.subscribeList && this.subscribeList.indexOf(p) > -1) {
        bool = true;
      }
    });
    return bool;
  }

  onSaleVideoSelectChange(): void {
    this.page = 1;
    const data = {
      isSaleVideo: true,
      page: this.page,
      performerId: this.performerId,
      status: 'active',
      take: this.take,
      sort: this.selectedSort,
      isMe: true
    };
    this.modelTaggedVideoService.getVideoList(data).subscribe(res => {
      if (res.statusCode === EStatusCode.OK) {
        if (this.page === 1) {
          this.totalActualVideos = res.count;
        }
        if (res.items.length) {
          this.videosList = [];
          res.items.forEach(item => {
            if (item.showPreview && item.thumbs.length || item.thumbs.length && this.isSubscribe(item)  ) {
              item.previewIndex = 0;
            }
            else{
              item.notSubscribe = 'assets/images/subscriber-thumb.png';
            }
            this.videosList.push(item);
          });
          this.totalCurrentVideos = this.videosList.length;
        }
      }
    }, error => {
      console.log(error);
    });
  }

  doLike(video): void {
    this.modelTaggedVideoService.doLike({
      id: video.id
    }).subscribe(res => {
      if (res.statusCode === EStatusCode.OK) {
        video.stats.totalLike = res.data.stats.totalLike;
      } else {
        this.toastr.error(res.message);
      }
    }, error => {
      console.log(error);
    });
  }

  addFavOrWatch(video, isFav): void {
    const type = isFav ? 'favorites' : 'watchlater';
    const data = {
      userId: this.userId,
      videoId: video.id,
      type: 'favorites'
    };
    this.modelHomeService.addFavourite(data).subscribe(res => {
      if (res.statusCode === EStatusCode.OK) {
        this.toastr.info(res.message);
      } else {
        this.toastr.error(res.message);
      }
    }, error => {
      console.log(error);
    });
  }

  navigateTo(video): void {
    this.router.navigate(['model/videoView/' + video._id]);
  }

  getSubscribeList(): void {
    this.loginService.getSubscribersService().subscribe(res => {
      let obj = [];
      if (res.statusCode === EStatusCode.OK) {
        if (Array.isArray(res.data)) {
          obj = res.data.map(item => item.performerId);
        }
        this.subscribeList = obj;
        this.getSaleVideo();
      }else {
        this.toastr.error(res.message);
        this.subscribeList = obj;
        this.getSaleVideo();
      }
    }, error => {
      console.log(error);
    });
  }
}
