import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AuthService} from './auth.service';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurd implements CanActivate {

  private role = localStorage.getItem('role');
  public dialog: MatDialog;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.role = localStorage.getItem('role');
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/login']);
      return false;
    } else {
      if (this.role === 'admin') {
        if (state.url.includes('admin')) {
          return true;
        } else {
          this.router.navigate(['admin/dashboard']);
          return false;
        }
      } else if (this.role === 'performer') {
        if (state.url.includes('model')) {
          return true;
        } else {
          this.router.navigate(['model/home']);
          return false;
        }
      }
      else if (this.role === 'user') {
        if (state.url.includes('user') || state.url.includes('model')) {
          return true;
        } else {
          this.router.navigate(['user/model/list']);
          return false;
        }
      }
      else {
        return true;
      }
    }
  }

}
