import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {RequestHandlerService} from './services/utils/Interceptor/request-handler.service';
import {ToastrModule} from 'ngx-toastr';
import {NgxUiLoaderConfig, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER} from 'ngx-ui-loader';
import {LayoutsModule} from './Layouts/layouts.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: '#5b6be8',
  bgsPosition: POSITION.bottomCenter,
  fgsSize: 100,
  fgsType: SPINNER.circle,
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 0,
};

export function initApp(http: HttpClient, translate: TranslateService) {
  return () => new Promise<boolean>((resolve: (res: boolean) => void) => {

    const defaultLocale = 'en';
    const translationsUrl = '/assets/i18n/';
    const sufix = '.json';
    const storageLocale = localStorage.getItem('locale');
    const locale = storageLocale || defaultLocale;

    http.get(`${translationsUrl}${locale}${sufix}`).pipe(
    ).subscribe((devKeys: any) => {
      translate.setTranslation(defaultLocale, devKeys || {});

      translate.setDefaultLang(defaultLocale);
      translate.use(defaultLocale);

      resolve(true);

      // Dont' Remove

      // const devKeys = response[0];
      // const translatedKeys = response[1];
      // translate.setTranslation(defaultLocale, devKeys || {});
      // translate.setTranslation(locale, translatedKeys || {}, true);
      // translate.setDefaultLang(defaultLocale);
      // translate.use(locale);
      //
      // resolve(true);
    });
  });
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    TranslateModule.forRoot(),
    LayoutsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestHandlerService,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [HttpClient, TranslateService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
