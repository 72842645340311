import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {API} from '../utils/constants/constant';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserHomeService {
  public changeProfileImage = new BehaviorSubject({status: false, url: ''});
  public convertToModel = new BehaviorSubject({status: false, userDetail: ''});
  public convertToUser = new BehaviorSubject({status: false, performerDetail: ''});
  public modelLogin = new BehaviorSubject({status: false, role: ''});

  constructor(private http: HttpClient) {
  }

  getCancelSubscriptionList(data): Observable<any> {
    return this.http.get(API.cancelSubscriptions + '?page=' + data.page + '&take=' + data.take);
  }

  getPaymentHistoryList(data): Observable<any> {
    return this.http.get(API.ordersList + '?take=' + data.take + '&page=' + data.page + '&status=active' + '&keyword=' + data.keyword + '&sort=' + data.sort + '&order=' + data.order);
  }

  getUserDetails(id): Observable<any> {
    return this.http.get(API.updateDetails + id);
  }

  updateUserDetails(id, data, updateType): Observable<any> {
    return this.http.put(API.updateDetails + id + '?updateType=' + updateType, data);
  }

  userContact(data): Observable<any> {
    return this.http.post(API.userContact, data);
  }

  getFavorites(data): Observable<any> {
    return this.http.get(API.modelSaveVideo + '?type=' + data.type + '&limit=' + data.limit + '&offset=' + data.offset);
  }
  updateUserIds( data): Observable<any> {
    return this.http.post(API.updateUserId, data);
  }
}
