import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from '@kolkov/ngx-gallery';
import {AppComponentBase} from '../AppComponentBase';

@Component({
  selector: 'app-ngx-galery-viewer',
  templateUrl: './ngx-galery-viewer.component.html',
  styleUrls: ['./ngx-galery-viewer.component.scss']
})
export class NgxGaleryViewerComponent extends AppComponentBase implements OnInit {

  public galleryOptions: NgxGalleryOptions[];
  public galleryImages: NgxGalleryImage[] = [];
  public galleryName: string;

  constructor(
    public dialogRef: MatDialogRef<NgxGaleryViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    inject: Injector
  ) {
    super(inject);
  }

  ngOnInit(): void {
    this.getPhotos();
    this.galleryName = this.data.albumName;
    this.galleryOptions = [
      {
        width: '100%',
        height: '600px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false,
        thumbnailsRemainingCount: true
      },
    ];
  }

  getPhotos(): void {
    this.data.gallery.forEach(image => {
      if (image?.imageThumbPath || image?.imageMediumPath || image?.imageFullPath) {
        this.galleryImages.push({
          small: image?.imageFullPath,
          medium: image?.imageFullPath,
          big: image?.imageFullPath,
        });
      }
    });
  }

  closeGallery(): void {
    this.dialogRef.close();
  }


}
