export interface IResponseList {
  data: IResList; // 200
  error: any; // "OK",
  code: EStatusCode; // keep it any
}

export interface IResList {
  totalCount: any;
  responseMsg: any;
  totalAmount: any;
  total: any;
  list: any;
}
export interface IResStatus {
  code: EStatusCode;
  status: string;
}
export enum EStatusCode {
  'OK' = 200,
  'CREATED' = 201,
  'CONFLICT' = 409,
  'INVALID_DATA' = 422,
  'LOCKED' = 423,
  'FAILURE' = 404,
  'INTERNAL_SERVER_ERROR' = 500,
  'Unauthorized' = 401,
  'tokenExpired' = 419,
}
export interface IResponse {
  result: any;
  data: any; // 200
  error: any; // "OK",
  info: IResStatus; // keep it any
  code: EStatusCode;
  responseMessage: string;
  file: any;
}
