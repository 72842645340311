<div>
  <div class="row">
    <div class="col-md-10 fd-padrev col-sm-6">
      <div class="fd-flex">
        <h3>{{galleryName}}</h3>
      </div>
    </div>
    <div class="col-md-2 col-sm-2 fd-mob-top">
      <mat-icon class="close-button" (click)="closeGallery()" matTooltip="Close">clear</mat-icon>
    </div>
  </div>
  <div class="row fd-ng" *ngIf="galleryOptions?.length && galleryImages?.length">
    <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
  </div>
</div>
