import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API} from '../utils/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public cardBuyEvent = new BehaviorSubject(false);
  constructor(private http: HttpClient) {
  }

  getStoreProducts(data): Observable<any> {
    return this.http.get(API.productDetail + '?page=' + data.page + '&performerId=' + data.performerId + '&status=' + data.status + '&take=' + data.take);
  }

  getProductListService(data): Observable<any> {
    return this.http.get(API.productDetail + '?page=' + data.page + '&performerId=' + data.performerId + '&take=' + data.take
      + '&order=' + data.order + '&sort=' + data.sort + '&keyword=' + data.keyword);
  }

  createProductService(data): Observable<any> {
    return this.http.post(API.createProduct, data);
  }

  getProductCategoriesList(): Observable<any> {
    return this.http.get(API.modelProductCatagories);
  }

  delete(id): Observable<any> {
    return this.http.post(API.deleteProduct + '?id=' + id, null);
  }

  getProductDetail(id): Observable<any> {
    return this.http.get(API.singleProductDetail + '?id=' + id);
  }

  updateProductService(id, data): Observable<any> {
    return this.http.put(API.updateProduct + id, data);
  }

  getProductList(filter): Observable<any> {
    return this.http.get(API.productList + '?type=' + filter.type + '&id='
      + filter.id + '&status=' + filter.status + '&limit=' + filter.limit + '&offset=' + filter.page);
  }
}
