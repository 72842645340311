import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {API} from '../utils/constants/constant';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminBannerService {

  constructor(private http: HttpClient) { }
  getBannersList(data): Observable<any> {
    return this.http.get(API.getBannerList + '?page=' + data.page + '&take=' + data.take + '&sort=' + data.sort +
     '&order=' + data.order + '&keyword=' + data.keyword);
  }
  deleteBanner(id): Observable<any> {
    return this.http.delete(API.viewUpdateDeleteBanner + id);
  }
  getBannerDetail(id): Observable<any> {
    return this.http.get(API.viewUpdateDeleteBanner + id);
  }
  createBanner(data): Observable<any> {
    return this.http.post(API.createBanner, data);
  }
  updateBanner(id, data): Observable<any> {
    return this.http.put(API.viewUpdateDeleteBanner + id, data);
  }
}
