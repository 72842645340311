import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API} from './utils/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class UpdateProfileService {

  constructor(private http: HttpClient) {
  }

  updateDetails(user: any, id: string): Observable<any> {
    return this.http.put(API.updateDetails + id + '/update-profile', user);
  }

  updateWelcomePhoto(data: FormData): Observable<any> {
    return this.http.post(API.updateWelcomePhoto, data);
  }

  updateCoverPhoto(data: FormData): Observable<any> {
    return this.http.post(API.updateCoverPhoto, data);
  }

  updateWelcomeVideo(data: FormData): Observable<any> {
    return this.http.post(API.updateWelcomeVideo, data);
  }

  getPerformerDetails(): Observable<any> {
    return this.http.get(API.loadMe);
  }
}
