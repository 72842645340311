import { NgModule } from '@angular/core';
import { LayoutsRoutingModule } from './layouts-routing.module';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {LayoutsComponent} from './layouts.component';
import {HeaderComponent} from './post-login/header/header.component';
import {SidebarComponent} from './post-login/sidebar/sidebar.component';
import {CommonModule} from '@angular/common';
import {CommonPipesModule} from '../common-pipes.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../components/core/material/material/material.module';

@NgModule({
  declarations: [
    LayoutsComponent,
    HeaderComponent,
    SidebarComponent,
  ],
  imports: [
    NgxUiLoaderModule,
    LayoutsRoutingModule,
    CommonModule,
    CommonPipesModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  exports: [LayoutsComponent]
})
export class LayoutsModule { }
