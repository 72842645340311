import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {API} from '../utils/constants/constant';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminOrdersService {

  constructor(private http: HttpClient) { }
  exportOrders(type: string, performerId: string): Observable<any> {
    return this.http.get(API.exportOrdersCsv + '?performerId=' + performerId + '&type=' + type);
  }
  getModelDropdownList(): Observable<any> {
    return this.http.get(API.modelDropdown);
  }
  getOrdersCount(): Observable<any> {
    return this.http.get(API.ordersCount);
  }
  deleteOrder(id): Observable<any> {
    return this.http.delete(API.deleteOrder + id);
  }
  getOrdersList(filter): Observable<any> {
    return this.http.get(API.ordersList + '?keyword=' + filter.keyword + '&page=' +
      filter.page + '&take=' + filter.limit + '&order=' + filter.order + '&sort=' + filter.sort + '&performerId=' + filter.performerId + '&type=' + filter.type);
  }

}
