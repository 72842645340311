import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API} from '../utils/constants/constant';


@Injectable({
  providedIn: 'root'
})
export class ModelPerformerDetailService {

  constructor(private http: HttpClient) {
  }

  getPerformerDetails(data): Observable<any> {
    if (data.isMe) {
      return this.http.get(API.modelsCount + '/' + data.performerId);
    } else {
      return this.http.get(API.modelsCount + '/' + data.performerId);
    }
  }


}
