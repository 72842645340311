import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {API} from '../utils/constants/constant';
import {HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class PayoutRequestService {

  constructor(private http: HttpClient) {
  }

  getPayoutRequestList(data): Observable<any> {
    if (!data.performerId) {
      return this.http.get(API.modelPayoutRequest + '?page=' + data.page + '&adminId=' +
        data.adminId + '&take=' + data.take + '&order=' + data.order + '&sort=' + data.sort + '&keyword=' + data.keyword);
    } else {
      return this.http.get(API.modelPayoutRequest + '?page=' + data.page + '&performerId=' +
        data.performerId + '&take=' + data.take + '&order=' + data.order + '&sort=' + data.sort + '&keyword=' + data.keyword);
    }
  }

  deletePayoutRequest(id): Observable<any> {
    return this.http.delete(API.updateViewDelPayout + id);
  }

  updatePayoutRequest(id, data): Observable<any> {
    return this.http.put(API.updateViewDelPayout + id, data);
  }

  getPayoutDetails(payoutId): Observable<any> {
    return this.http.get(API.modelPayoutRequestDetail + payoutId);
  }

  payBackMoney(id): Observable<any> {
    return this.http.get(API.payBackMoney + '/' + id);
  }
  updateEarning(data): Observable<any> {
    return this.http.put(API.earning , data);
  }
  getComment(data): Observable<any> {
    return this.http.get(API.payoutComment + '?payoutId=' + data.payoutId  + '&page=' + data.page + '&take=' + data.take);
  }
  createComment(data): Observable<any> {
    return this.http.post(API.createPayoutComment , data);
  }
}
