export const environment = {
  production: true,
  // BASE_URL: 'http://54.176.211.169:3000/', // Client productionMicro,
  // BASE_URL: 'http://3.6.121.105:3000/', // SS productionMicro
  // BASE_URL: 'http://localhost:3000/',
  BASE_URL: 'https://fanswise-xl.com/', // Client productionMicro SSL,
  API_ENDPOINT: 'api/V1/',
  CLIENT_ID: 'AWh795zoFpqNpkMSB7EaU4qrMxPnjRHK7BPH-b39XFESXKTzHnpUAQQliatdFpupXfZrUBoxrLn8Tz7x' // production
  // CLIENT_ID: 'AZz2eRCX1iZX_91S-nmQnP9qTlQ2IJXJO4S8wQmqiTePFy-nRtV6KHgp-qnFIJw6vOIe98k1wKtZfCd7' // local
};
