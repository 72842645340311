import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {API} from '../utils/constants/constant';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminPagelistService {

  constructor(private http: HttpClient) { }

  getPageList(filter): Observable<any> {
    return this.http.get(API.pageList + '?limit=' + filter.take + '&offset=' + filter.page +'&keyword=' +
     filter.keyword + '&page=' + filter.page + '&order=' + filter.order + '&sort=' + filter.sort);
  }
  getPageDetail(id: string): Observable<any> {
    return this.http.get(API.pageDetail + id);
  }
  addPage(newPageDetail): Observable<any> {
    return this.http.post(API.pageList, newPageDetail);
  }
  updatePageDetail(id, pageDetail): Observable<any> {
    return this.http.put(API.updatePage + id , pageDetail);
  }
  deletePage(id, pageDetail?): Observable<any> {
    return  this.http.delete(API.deletePage + id);
  }
}
