import {Injectable} from '@angular/core';
import * as io from 'socket.io-client';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private socket: SocketIOClient.Socket;
  private opts: SocketIOClient.ConnectOpts;

  constructor() {
    this.opts = {reconnection: true};
  }

  connectToServer(): SocketIOClient.Socket{
    try {
      this.socket = io(environment.BASE_URL, this.opts);
      return this.socket;
    } catch (ex) {
      console.log('Error while connecting to Signaling Server.');
      return null;
    }
  }

  emit(event: string, data: any, listener: any = () => {}): void {
    this.socket.emit(event, data, listener);
  }

  on(event: string): Observable<any> {
    return Observable.create(observer => {
      this.socket.on(event, data => {
        observer.next(data);
      });
    });
  }

  disconnect(): void {
    this.socket.disconnect();
  }

}
