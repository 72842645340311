import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API} from '../utils/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class AdminPhotoService {

  constructor(private http: HttpClient) {
  }

  getPhotoList(data): Observable<any> {
    return this.http.get(API.photoList + '?page=' + data.page + '&take=' + data.take
      + '&sort=' + data.sort + '&order=' + data.order + '&keyword=' + data.keyword);
  }

  deletePhoto(id): Observable<any> {
    return this.http.delete(API.deletePhoto + '?id=' + id);
  }

  getAlbums(): Observable<any> {
    return this.http.get(API.albumList);
  }

  addPhoto(data): Observable<any> {
    return this.http.post(API.addPhoto, data);
  }

  getModelDropdownList(): Observable<any> {
    return this.http.get(API.modelDropdown);
  }

  getPhotoDetail(id): Observable<any> {
    return this.http.get(API.getPhoto + '?id=' + id);
  }

  updatePhoto(id, data): Observable<any> {
    return this.http.put(API.updatePhoto + id, data);
  }

  bulkUploadPhoto(data): Observable<any> {
    return this.http.post(API.bulkUpload, data);
  }
}
