import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {AppComponentBase} from '../../../shared/AppComponentBase';
import {EStatusCode} from '../../../services/utils/constants/response-handler';
import {CommonDialogModelComponent} from '../../../shared/common-dialog-model/common-dialog-model.component';

@Component({
  selector: 'app-model-home',
  templateUrl: './model-home.component.html',
  styleUrls: ['./model-home.component.scss']
})
export class ModelHomeComponent extends AppComponentBase implements OnInit, OnDestroy {

  public role: string;
  public userRoleCheck: string;
  public performerId: string;
  public userId: string;
  public performerDetail: any;
  public isMe = false;
  public isSubscribed = 0;
  public userRole: string;
  public autoPlay: boolean;

  constructor(private inject: Injector) {
    super(inject);
    this.userRoleCheck = localStorage.getItem('role');
    if (this.route.snapshot.paramMap.get('name')) {
      const performerName = this.route.snapshot.paramMap.get('name');
      this.modelHomeService.getPerformerByName(performerName).subscribe((res) => {
        if (res.statusCode.Ok === EStatusCode.OK && res.performerId) {
          this.performerId = res.performerId;
        } else {
          this.performerId = localStorage.getItem('performerId');
        }
        if (this.userRoleCheck === 'performer') {
          this.userId = localStorage.getItem('performerId');
          if (this.performerId === this.userId) {
            this.isMe = true;
          } else {
              this.modelHomeService.updateViewCount(this.performerId).subscribe(response => {
              });
          }
        } else {
          this.modelHomeService.updateViewCount(this.performerId).subscribe(response => {
          });
        }
        this.initializeVariables();
      });
    } else {
      this.performerId = localStorage.getItem('performerId');
      if (this.userRoleCheck === 'performer') {
        this.userId = localStorage.getItem('performerId');
        if (this.performerId === this.userId) {
          this.isMe = true;
        }  else {
          this.modelHomeService.updateViewCount(this.performerId).subscribe(response => {
            console.log('Profile View has updated');
          });
        }
      }
      this.initializeVariables();
    }
  }

  ngOnInit(): void {
  }

  initializeVariables(): void {
    this.getPerformerDetails();
    this.checkSubscriptions();
    this.userRole = localStorage.getItem('role');
    localStorage.setItem('playingVideoId', '');
  }

  ngOnDestroy(): void {
  }

  getPerformerDetails(): void {
    this.modelPerformerDetailService.getPerformerDetails({
      performerId: this.performerId,
      isMe: this.isMe
    }).subscribe(res => {
      if (res.statusCode === EStatusCode.OK) {
        this.performerDetail = res.data;
        this.modelHomeService.performerProfile = this.performerDetail.imageFullPath ? this.performerDetail.imageFullPath : '';
        this.modelHomeService.performerCover = this.performerDetail.welcomePhoto ? this.performerDetail.welcomePhoto : '';
        this.modelHomeService.performerId = this.performerDetail.id;
        this.modelHomeService.performerProfileListener.next(true);
        this.modelHomeService.performerCoverListener.next(true);
      } else {
        this.performerDetail = null;
      }
    }, error => {
      this.performerDetail = null;
    });
  }

  checkSubscriptions(): void {
    if (!this.isMe) {
      this.modelHomeService.checkSubscriptionService(this.performerId).subscribe(res => {
        if (res.statusCode === EStatusCode.OK) {
          this.isSubscribed = res.data;
        }
      }, error => {
        this.isSubscribed = 0;
      });
    }
  }

  onCancelSubscription(): void {
    const dialogRef = this.dialog.open(CommonDialogModelComponent, {
      data: {action: 'cancelSubscription'},
      width: '500px',
      height: '500px'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loader.start();
        this.paymentService.cancelSubscription({performerId: this.performerId}).subscribe(res => {
          this.loader.stop();
          if (res.statusCode === EStatusCode.OK) {
            this.toastr.success(res.message);
            this.isSubscribed = 0;
          } else {
            this.loader.stop();
            this.toastr.error(res.message);
          }
        });
      }
    });
  }

  navigateToPay(isMonthly: boolean): void {
    if (isMonthly) {
      this.router.navigate(['model/payment'], {
        queryParams: {
          type: 'performer_subscription',
          package: 'monthly',
          performer: this.performerId
        }
      });
    } else {
      this.router.navigate(['model/payment'], {
        queryParams: {
          type: 'performer_subscription',
          package: 'yearly',
          performer: this.performerId
        }
      });
    }
  }

  navigateToMessage(performerId): void {
    this.router.navigate(['model/messages'], {queryParams: {recipientId: performerId}});
  }
}
