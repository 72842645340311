import {Component, OnInit, Injector, OnDestroy, Input} from '@angular/core';
import {AppComponentBase} from '../../../../../shared/AppComponentBase';
import {EStatusCode} from '../../../../../services/utils/constants/response-handler';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent extends AppComponentBase implements OnInit, OnDestroy {

  @Input() performerId: string;
  public selectedSort = 'newest';
  public sorts: string[];
  public catagories: any[];
  public totalCurrentVideos = 0;
  public videosList: any[] = [];
  public totalActualVideos = 0;
  public keyword: string;
  public searchMode: boolean;
  public performerProfile: string;
  public performerCoverPhoto: string;
  private productCategories = [{id: '', name: 'All'}];
  private videoCatagories = [{id: '', name: 'All'}];
  private performerProfileSubscription: any;
  private performerCoverSubscription: any;
  private page = 1;
  private take = 12;
  private preview: any;
  private isMe: boolean;
  private userId: string;
  private subscribeList = [];

  constructor(private inject: Injector) {
    super(inject);
    this.performerProfileSubscription = this.modelHomeService.performerProfileListener.subscribe(res => {
      if (res) {
        this.performerProfile = this.modelHomeService.performerProfile;
        this.performerId = this.modelHomeService.performerId;
        this.isMe = this.userId === this.performerId;
        this.getSubscribeList();
      }
    });
    this.performerCoverSubscription = this.modelHomeService.performerCoverListener.subscribe(res => {
      if (res) {
        this.performerCoverPhoto = this.modelHomeService.performerCover;
      }
    });
  }

  ngOnInit(): void {
    this.sorts = ['newest', 'oldest', 'mostLike', 'mostView'];
    // this.getSubscribeList();
    this.getCategories();
    this.getProductCategories();
    this.performerProfile = this.modelHomeService.performerProfile;
    this.userId = localStorage.getItem('performerId');
    this.isMe = this.userId === this.performerId;
    this.role = localStorage.getItem('role');
    if (this.role === 'user') {
      this.userId = localStorage.getItem('userId');
    }
  }

  ngOnDestroy(): void {
    this.performerProfileSubscription.unsubscribe();
    this.performerCoverSubscription.unsubscribe();
  }

  applySearchFilter(): void {
    this.router.navigate(['model/search-video'], {
      queryParams: {
        keyword: this.keyword.trim(),
        performer: this.performerId
      }
    });
  }


  getCategories(): void {
    this.modelCatagoriesService.getCatagoriesList().subscribe(res => {
      if (res.statusCode === EStatusCode.OK) {
        res.data.items.forEach(item => {
          this.videoCatagories.push(item);
        });
        this.catagories = this.videoCatagories;
      }
    }, error => {
      this.catagories = [];
    });
  }

  getProductCategories(): void {
    this.modelHomeService.getProductServices().subscribe(res => {
      if (res.statusCode === EStatusCode.OK) {
        res.items.forEach(item => {
          item.id = item._id;
          this.productCategories.push(item);
        });
      }
    }, error => {
    });
  }

  getVideo(): void {
    if (this.performerId) {
      this.modelTaggedVideoService.getVideoList({
        isSaleVideo: false,
        page: this.page,
        performerId: this.performerId,
        status: 'active',
        take: this.take,
        sort: this.selectedSort,
        isMe: this.isMe
      }).subscribe(res => {
        if (res.statusCode === EStatusCode.OK) {
          if (this.page === 1) {
            this.totalActualVideos = res.count;
          }
          if (res.items && res.items.length) {
            res.items.forEach(item => {
              if (item.showPreview && item.thumbs.length || item.thumbs.length && this.isSubscribe(item)) {
                item.previewIndex = 0;
              } else {
                item.notSubscribe = 'assets/images/subscriber-thumb.png';
              }
              this.videosList.push(item);
            });
            this.totalCurrentVideos = this.videosList.length;
          }
        } else {
          this.toastr.error(res.message);
        }
      }, error => {
        this.totalActualVideos = 0;
        this.totalCurrentVideos = 0;
      });
    }
  }

  onScroll(event): void {
    console.log(event, 'scored');
  }

  loadMore(): void {
    this.page++;
    this.getVideo();
  }

  doLike(video): void {
    const data = {
      id: video.id
    };
    this.modelTaggedVideoService.doLike(data).subscribe(res => {
      if (res.statusCode === EStatusCode.OK) {
        video.stats.totalLike = res.data.stats.totalLike;
        this.toastr.success(res.message);
      } else {
        this.toastr.error(res.message);
      }
    }, error => {
    });
  }

  isSubscribe(item): boolean {
    let bool: boolean;
    (item.performer || []).forEach(p => {
      if (this.subscribeList.indexOf(p) > -1) {
        bool = true;
      }
    });
    return bool;
  }


  onSortSelectChange(event): void {
    this.searchMode = false;
    this.selectedSort = event.value;
    this.keyword = '';
    this.page = 1;
    const data = {
      isSaleVideo: false,
      page: this.page,
      performerId: this.performerId,
      status: 'active',
      take: this.take,
      sort: this.selectedSort,
      isMe: this.isMe
    };
    this.loader.start();
    this.modelTaggedVideoService.getVideoList(data).subscribe(res => {
      if (res.statusCode === EStatusCode.OK) {
        if (this.page === 1) {
          this.totalActualVideos = res.count;
        }
        if (res.items.length) {
          this.videosList = [];
          res.items.forEach(item => {
            if (item.showPreview && item.thumbs.length || item.thumbs.length && this.isSubscribe(item)) {
              item.previewIndex = 0;
            } else {
              item.notSubscribe = 'assets/images/subscriber-thumb.png';
            }
            this.videosList.push(item);
          });
          this.totalCurrentVideos = this.videosList.length;
        }
      } else {
        this.videosList = [];
        this.totalCurrentVideos = 0;
        this.toastr.error(res.message);
      }
    }, error => {
      this.videosList = [];
      this.totalCurrentVideos = 0;
    });
    this.loader.stop();
  }

  changeNavigation(video): void {
    this.router.navigate(['model/videoView/' + video._id]);
    this.modelHomeService.performerCover = this.performerCoverPhoto ? this.performerCoverPhoto : '';
    this.modelHomeService.performerCoverListener.next(true);
  }

  addFavOrWatch(video, isFav): void {
    const type = isFav ? 'favorites' : 'watchlater';
    const data = {
      userId: this.userId,
      videoId: video.id,
      type
    };
    this.modelHomeService.addFavourite(data).subscribe(res => {
      if (res.statusCode === EStatusCode.OK) {
        this.toastr.info(res.message);
      } else {
        this.toastr.error(res.message);
      }
    }, error => {
    });
  }

  getSubscribeList(): void {
    this.loginService.getSubscribersService().subscribe(res => {
      let obj = [];
      if (res.statusCode === EStatusCode.OK) {
        if (Array.isArray(res.data)) {
          obj = res.data.map(item => item.performerId);
        }
      }
      this.subscribeList = obj;
      this.getVideo();
    }, error => {
    });
  }

}
