import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API} from '../utils/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class AdminVideoCategoryService {

  constructor(private http: HttpClient) { }
  getVideoCategoryList(data): Observable<any> {
    return this.http.get(API.videoCategory + '?take=' + data.take + '&page=' + data.page + '&keyword=' + data.keyword +
    '&order=' + data.order + '&sort=' + data.sort);
  }
  deleteVideoCategory(id): Observable<any> {
    return this.http.delete(API.viewUpdateVideoCategory + id);
  }
  getVideoCategorydetails(id): Observable<any> {
    return this.http.get(API.viewUpdateVideoCategory + id);
  }
  createVideoCategory(data): Observable<any> {
    return this.http.post(API.videoCategory, data);
  }
  updateVideoCategory(id, data): Observable<any> {
    return this.http.put(API.viewUpdateVideoCategory + id, data);
  }
}
