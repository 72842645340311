import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {API} from '../utils/constants/constant';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminSettingsService {

  constructor(private http: HttpClient) {
  }

  getSettingsList(filter): Observable<any> {
    return this.http.get(API.settings + `?limit=${filter.take}&offset=${filter.settingsCount}`);
  }

  getSettingDetail(id: string): Observable<any> {
    return this.http.get(API.settings + '/' + id);
  }
  updateSettingDetail(id, settingDetails): Observable<any> {
    return this.http.put(API.settings + '/' + id, settingDetails);
  }
  // exportSubscribes(id): Observable<any> {
  //   return this.http.get(API.exportSubscribersCsv + id + '/subscribers');
  //  }
  // exportPerformers(): Observable<any> {
  //   return this.http.get(API.exportPerformerCsv );
  //  }
}
