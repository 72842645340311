import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGaurd} from '../services/utils/AuthGaurd/AuthGaurd';
import {ModelHomeComponent} from '../components/models/model-home/model-home.component';

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'login'},
  {path: 'signup', loadChildren: () => import('../components/core/register/register.module').then(m => m.RegisterModule)},
  {path: 'login', loadChildren: () => import('../components/core/login/login.module').then(m => m.LoginModule)},
  {
    path: 'forgot-password',
    loadChildren: () => import('../components/core/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {path: 'admin', canActivate: [AuthGaurd], loadChildren: () => import('../components/admin/admin.module').then(m => m.AdminModule)},
  {path: 'model', canActivate: [AuthGaurd], loadChildren: () => import('../components/models/model.module').then(m => m.ModelModule)},
  {path: 'user', canActivate: [AuthGaurd], loadChildren: () => import('../components/users/user.module').then(m => m.UserModule)},
  {
    path: ':name',
    component: ModelHomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutsRoutingModule {
}
