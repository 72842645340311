import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {API} from '../utils/constants/constant';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AdminEarningStatsService {

  constructor(private http: HttpClient) {}

  getEarningStats(date): Observable<any> {
    return this.http.get(API.earningStats + '?dateFrom=' + date.from + '&dateTo=' + date.to);
  }

  getEarningList(filter): Observable<any> {
    return this.http.get(API.earningList + '?dateFrom=' + filter.from + '&dateTo=' + filter.to + '&take=' + filter.take +
    '&page=' + filter.page + '&type=' + filter.type + '&order=' + filter.order + '&sort=' + filter.sort +
      '&keyword=' + filter.keyword.toLowerCase() + '&performerId=' + filter.performerId);
  }
  deleteEarningList(id): Observable<any> {
    return this.http.delete(API.deleteEarning + id);
  }


}
