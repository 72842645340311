<div class="body">
  <div class="row">
    <div class="mb-0 col-sm-12">
      <div *ngIf="searchMode">
        <div class="header">
          <h2 class="font-bold">{{keyword}}({{totalCurrentVideos}})</h2>
        </div>
      </div>
    </div>
    <div class="mb-0 col-lg-4 col-sm-6">
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-label>{{'search' | translate}}</mat-label>
        <input matInput [(ngModel)]="keyword" [maxlength]="maxSearchLength" (keyup.enter)="applySearchFilter()"
               (blur)="applySearchFilter()">
        <mat-icon matSuffix (click)="applySearchFilter()">search</mat-icon>
      </mat-form-field>
    </div>
    <div class="mb-0 ml-lg-auto col-lg-4 col-sm-6">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>{{'sort'|translate}}</mat-label>
        <mat-select [(value)]="selectedSort" (selectionChange)="onSortSelectChange($event)">
          <mat-option *ngFor="let sort of sorts" [value]="sort">
            {{sort| translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="videosList.length">
    <div class="row">
      <div *ngFor="let video of videosList" class="mb-0 col-xl-3 col-sm-6">
        <mat-card>
          <mat-card-header>
            <img mat-card-avatar src="{{performerProfile | checkImage}}">
            <mat-card-title (click)="changeNavigation(video)" matTooltip="{{video?.name}}"
                            style="cursor: pointer">{{video?.name}}</mat-card-title>
            <mat-card-subtitle>{{video?.createdAt | date}}</mat-card-subtitle>
            <div class="header-dropdown" *ngIf="role=='user'">
              <button mat-icon-button [matMenuTriggerFor]="videoMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #videoMenu="matMenu">
                <button mat-menu-item (click)="addFavOrWatch(video, false)">
                  <mat-icon>access_time</mat-icon>
                  <span>{{'watchLater'|translate}}</span>
                </button>
                <button mat-menu-item (click)="addFavOrWatch(video, true)">
                  <mat-icon>star</mat-icon>
                  <span>{{'addFav'| translate}}</span>
                </button>
              </mat-menu>
            </div>
          </mat-card-header>

          <a (click)="changeNavigation(video)" style="cursor: pointer">
            <app-model-video-gif-view [video]="video"></app-model-video-gif-view>
          </a>
          <mat-card-actions>
            <div fxLayout="row" class="align-items-center">
              <button mat-icon-button style="cursor: text">
                <mat-icon class="greyColor" style="cursor: text">thumb_up</mat-icon>
              </button>
              <span>({{video?.stats?.totalLike}})</span>
              <button mat-icon-button style="cursor: text">
                <mat-icon class="greyColor" style="cursor: text">
                  comment
                </mat-icon>
              </button>
              <span>({{video?.stats?.totalComment}})</span>
              <button mat-icon-button style="cursor: text">
                <mat-icon class="greyColor" style="cursor: text">
                  remove_red_eye
                </mat-icon>
              </button>
              <span>({{video?.stats?.totalView}})</span>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
    <div class="row" *ngIf="!searchMode && totalCurrentVideos < totalActualVideos ">
      <div class="mb-0 col-sm-12 load-more">
        <button mat-raised-button (click)="loadMore()" color="primary" class="seemore">{{'loadMore'|translate}}</button>
      </div>
    </div>
  </div>
  <div *ngIf="!videosList.length" class="row ml-2">
    <p class="no-record-found">{{'noRecordFound' | translate}}</p>
  </div>
</div>
