import {environment} from '../../../../environments/environment';
import {RouteInfo} from '../../../components/models/sidebar.metadata';


export const API_ENDPOINT_URL = environment.BASE_URL + environment.API_ENDPOINT;

export const API = {
  login: API_ENDPOINT_URL + 'signIn',
  refreshToken: API_ENDPOINT_URL + 'refreshToken',
  modelList: API_ENDPOINT_URL + 'performers/search',
  DeleteModel: API_ENDPOINT_URL + 'performerDelete/',
  getModelDetails: API_ENDPOINT_URL + 'performers/',
  updatePassword: API_ENDPOINT_URL + 'user/updatePassword',
  modelTaggedVideoList: API_ENDPOINT_URL + 'videos/search',
  modelDropdown: API_ENDPOINT_URL + 'performer/dropdown',
  modelCatagories: API_ENDPOINT_URL + 'categories',
  profileViewStats: API_ENDPOINT_URL + 'stats/profile-view',
  likeStats: API_ENDPOINT_URL + 'stats/like',
  subscriberStats: API_ENDPOINT_URL + 'stats/subscribers',
  commentStats: API_ENDPOINT_URL + 'stats/comments',
  topVideo: API_ENDPOINT_URL + 'videos/topVideos',
  performerDetail: API_ENDPOINT_URL + 'performerDetail',
  updatePerformer: API_ENDPOINT_URL + 'updatePerformer/admin/',
  updatePerformerDetails: API_ENDPOINT_URL + 'performer/',
  productDetail: API_ENDPOINT_URL + 'products/store',
  messageGroups: API_ENDPOINT_URL + 'messages/groups',
  modelPayoutRequest: API_ENDPOINT_URL + 'payouts/search',
  earningStats: API_ENDPOINT_URL + 'earning/stats',
  earningList: API_ENDPOINT_URL + 'earning/list',
  createCalenderEvent: API_ENDPOINT_URL + 'calendar',
  modelPayoutRequestDetail: API_ENDPOINT_URL + 'payouts/',
  modelAlbums: API_ENDPOINT_URL + 'albums/all',
  modelPhoto: API_ENDPOINT_URL + 'photos/list',
  modelVideoLike: API_ENDPOINT_URL + 'videos/like',
  modelRelatedVideo: API_ENDPOINT_URL + 'videos/',
  modelSearchVideo: API_ENDPOINT_URL + 'search',
  modelProductCatagories: API_ENDPOINT_URL + 'products-catagories',
  modelProductCatagory: API_ENDPOINT_URL + 'products-catagory/',
  createProductCatagories: API_ENDPOINT_URL + 'create/products-catagories',
  DeleteProductCatagories: API_ENDPOINT_URL + 'delete/products-catagories/',
  UpdateProductCatagory: API_ENDPOINT_URL + 'update/products-catagory/',
  updateViewDelPayout: API_ENDPOINT_URL + 'payouts/',
  calenderEventList: API_ENDPOINT_URL + 'model/calender-event',
  calenderEventForm: API_ENDPOINT_URL + 'model/calender-event/create',
  modelSaveVideo: API_ENDPOINT_URL + 'save-video',
  getSubscribes: API_ENDPOINT_URL + 'subscribers/performers',
  modelsCount: API_ENDPOINT_URL + 'performers',
  getComments: API_ENDPOINT_URL + 'comments/list',
  messageInGroup: API_ENDPOINT_URL + 'messages/groups/',
  checkPerformerAllow: API_ENDPOINT_URL + 'performers/check/allow',
  checkBlock: API_ENDPOINT_URL + 'blocks/check',
  sendComment: API_ENDPOINT_URL + 'comments/',
  saveMessage: API_ENDPOINT_URL + 'messages',
  checkSubscriptions: API_ENDPOINT_URL + 'subscriptions/check/',
  getAllMOdel: API_ENDPOINT_URL + 'performers/search',
  createCalender: API_ENDPOINT_URL + 'calendar',
  updateViewDelCalenderEvent: API_ENDPOINT_URL + 'calendar/event/',
  calenderEvents: API_ENDPOINT_URL + 'calendar/events',
  notificationList: API_ENDPOINT_URL + 'notifications',
  modelVideoList: API_ENDPOINT_URL + 'videos',
  modelTags: API_ENDPOINT_URL + 'videos/tags',
  modelPhotosList: API_ENDPOINT_URL + 'photos/list',
  modelPhotodelete: API_ENDPOINT_URL + 'photos/deletePhoto',
  updateDetails: API_ENDPOINT_URL + 'users/',
  createProduct: API_ENDPOINT_URL + 'products',
  deleteProduct: API_ENDPOINT_URL + 'products/view-delete',
  singleProductDetail: API_ENDPOINT_URL + 'products/view-delete',
  updateProduct: API_ENDPOINT_URL + 'products/update/',
  photoList: API_ENDPOINT_URL + 'photos/list',
  addPhoto: API_ENDPOINT_URL + 'photos/addPhoto',
  deletePhoto: API_ENDPOINT_URL + 'photos/deletePhoto',
  updateWelcomePhoto: API_ENDPOINT_URL + 'performers/avatar',
  updateCoverPhoto: API_ENDPOINT_URL + 'files/upload',
  updateWelcomeVideo: API_ENDPOINT_URL + 'files/video-welcome',
  loadMe: API_ENDPOINT_URL + 'me',
  getPhoto : API_ENDPOINT_URL + 'photos',
  updatePhoto: API_ENDPOINT_URL + 'photos/updatePhoto/',
  albumList: API_ENDPOINT_URL + 'list',
  createAlbum: API_ENDPOINT_URL + 'add/albums',
  albumCount: API_ENDPOINT_URL + 'album/count',
  deleteAlbum: API_ENDPOINT_URL + 'albums',
  getPerformerAlbum: API_ENDPOINT_URL + 'albums/all',
  getAlbumDetail: API_ENDPOINT_URL + 'albums',
  updateAlbum: API_ENDPOINT_URL + 'albums/update/',
  bulkUpload: API_ENDPOINT_URL + 'photos/bulkUpload',
  updateVideo: API_ENDPOINT_URL + 'videos/',
  readAlert: API_ENDPOINT_URL + 'notifications/alert/read',
  exportUserCsv: API_ENDPOINT_URL + 'export-csv/users',
  exportPerformerCsv: API_ENDPOINT_URL + 'export-csv/performers',
  exportSubscribersCsv: API_ENDPOINT_URL + 'export-csv/subscribers/',
  exportOrdersCsv: API_ENDPOINT_URL + 'export-csv/orders',
  getPerformer: API_ENDPOINT_URL + 'performers',
  getFTPOptions: API_ENDPOINT_URL + 'video/folder',
  settings: API_ENDPOINT_URL + 'settings',
  ordersCount: API_ENDPOINT_URL + 'orders/count',
  ordersList: API_ENDPOINT_URL + 'orders',
  deleteOrder: API_ENDPOINT_URL + 'order/',
  usersCount: API_ENDPOINT_URL + 'users',
  productCount: API_ENDPOINT_URL + 'products/count',
  pageList: API_ENDPOINT_URL + 'pages',
  updatePage: API_ENDPOINT_URL + 'pages/',
  deletePage: API_ENDPOINT_URL + 'pages/',
  pageDetail: API_ENDPOINT_URL + 'pages/',
  createUser: API_ENDPOINT_URL + 'users',
  updateUser: API_ENDPOINT_URL + 'users/',
  deleteUser: API_ENDPOINT_URL + 'users/',
  setting : API_ENDPOINT_URL + 'settings/get/full',
  paypal : API_ENDPOINT_URL + 'paypal/',
  paypalCallback: API_ENDPOINT_URL + 'paypal/callback',
  paypalCancel : API_ENDPOINT_URL + 'paypal/cancelSubscription',
  getCouponsList: API_ENDPOINT_URL + 'coupons',
  viewDeleteUpdate: API_ENDPOINT_URL + 'coupons/',
  createCoupon: API_ENDPOINT_URL + 'coupons',
  subscriberList: API_ENDPOINT_URL + 'subscribers',
  usersDropdown: API_ENDPOINT_URL + 'usersDropdown',
  performerFile: API_ENDPOINT_URL + 'performers/files',
  videoCategory: API_ENDPOINT_URL + 'categories',
  viewUpdateVideoCategory: API_ENDPOINT_URL + 'categories/',
  getBannerList: API_ENDPOINT_URL + 'banners',
  viewUpdateDeleteBanner: API_ENDPOINT_URL + 'banners/',
  createBanner: API_ENDPOINT_URL + 'banners',
  createModel: API_ENDPOINT_URL + 'performer',
  getImage: API_ENDPOINT_URL + 'performers/files/',
  updateIp: API_ENDPOINT_URL + 'ip-update',
  cancelSubscriptions: API_ENDPOINT_URL + 'cancelSubscriptions',
  userContact: API_ENDPOINT_URL + 'settings/contact',
  payBackMoney: API_ENDPOINT_URL + 'adminPayBackMoney',
  loginAsModel: API_ENDPOINT_URL + 'model/login',
  notificationAlert: API_ENDPOINT_URL + 'notifications/alert',
  updateIP: API_ENDPOINT_URL + 'ip-update',
  productList: API_ENDPOINT_URL + 'products',
  earning : API_ENDPOINT_URL + 'earning/paid',
  payoutComment: API_ENDPOINT_URL + 'comment/search',
  createPayoutComment: API_ENDPOINT_URL + 'comment/type',
  coupon : API_ENDPOINT_URL + 'coupon',
  convertToModel: API_ENDPOINT_URL + 'convertToModel',
  userIpList: API_ENDPOINT_URL + 'user-ips',
  createSubscription: API_ENDPOINT_URL + 'subscriptions/add',
  forgotPassword: API_ENDPOINT_URL + 'users/forgot',
  deleteEarning: API_ENDPOINT_URL + 'earning/',
  loginAsUser: API_ENDPOINT_URL + 'user/login',
  registerPage: API_ENDPOINT_URL + 'register/user',
  convertToUser: API_ENDPOINT_URL + 'convertToUser',
  updateUserId: API_ENDPOINT_URL + 'user/Id',
  creditDebitCard : API_ENDPOINT_URL + 'debit',
  updateCloudFlareStatus: API_ENDPOINT_URL + 'updateCloudFlareStatus',
  performerByName: API_ENDPOINT_URL + 'performerByName',
  deleteRequestModel: API_ENDPOINT_URL + 'deleteRequestModel/',
  addPerformerViewCount: API_ENDPOINT_URL + 'updateView'
};

export const HeaderRoute: RouteInfo[] = [
  {
    path: 'model/account-information',
    title: 'Account',
    icon: 'fas fa-user-alt',
    class: '',
    groupTitle: false
  },
  {
    path: 'model/video-manager',
    title: 'Videos manager',
    icon: 'fas fa-camera',
    class: '',
    groupTitle: false
  },
  {
    path: 'model/photo-manager',
    title: 'Photos manager',
    icon: 'fas fa-images',
    class: '',
    groupTitle: false
  },
  {
    path: 'model/calendar',
    title: 'Products manager',
    icon: 'fas fa-shopping-cart',
    class: '',
    groupTitle: false
  },
  {
    path: 'model/earnings',
    title: 'Earnings',
    icon: 'fas fa-dollar-sign',
    class: '',
    groupTitle: false
  },
  {
    path: 'model/payout-request',
    title: 'Payout Requests',
    icon: 'fas fa-money-bill',
    class: '',
    groupTitle: false
  },
  {
    path: 'model/messages',
    title: 'Messages',
    icon: 'fas fa-comment-alt',
    class: '',
    groupTitle: false
  },
  {
    path: 'model/statistics',
    title: 'Statistics',
    icon: 'fas fa-chart-bar',
    class: '',
    groupTitle: false
  },
  {
    path: 'logout',
    title: 'Log out',
    icon: 'fas fa-power-off',
    class: '',
    groupTitle: false
  }
];

export const ResponseMessages = {
  loginSuccess: 'Login Successful',
  allowImage: 'Please select image with .jpg,.png format',
  noRecordFound: 'No record found',
  fillRequired: 'Please fill all required fields',
  commentRequired: 'Please atleast Enter 6 Character',
  accountInformation: 'Please save the changes',
  videoUploadMessage: 'Please check the Video',
  videoUploadErr: 'please check the input field',
  payoutRequestInfo: 'Don\'t refresh while updating status',
  cardAdded: 'Added to card',
  alreadyAdded: 'Already the product is added to the Card',
  cardRemoved: 'Card removed successfully',
  productQuantity: 'Product Quantity maximum',
  productQuantityLimit: 'Product Quantity should be greater than zero',
  QuantityUpdated: 'Quantity Updated successfully',
  notPossibleToAdd: 'You can shop with one model at the time'
};

export const COMMON = {
  IMAGE_VIEWER_HEIGHT: 'auto',
  IMAGE_VIEWER_WIDTH: '80vw'
};
