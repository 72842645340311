<div class="profile-container">
  <div class="card bg-transparent mb-0">
    <div class="contact-grid">
      <div class="profile-header cover" style="background: url( {{performerDetail?.welcomePhoto | checkImage: 'cover'}});">
        <div class="performer-name">
          <div class="user-name">{{performerDetail?.username || performerDetail?.name}}</div>
          <div class="name-center">{{'model'| translate}}</div>
        </div>
      </div>
      <div *ngIf="!performerDetail?.welcomeOption || performerDetail?.welcomeOption === 'photo' || performerDetail?.welcomeOption === 'video' && !performerDetail?.welcomeVideo">
        <img src='{{performerDetail?.imageFullPath | checkImage: null}}' class="user-img" alt="">
      </div>
      <div *ngIf="performerDetail && performerDetail.welcomeOption && performerDetail.welcomeVideo && performerDetail.welcomeOption === 'video'">
        <vg-player class="user-video mx-auto">
          <vg-controls>
            <div class="row">
              <vg-play-pause></vg-play-pause>
              <vg-scrub-bar>
                <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
              </vg-scrub-bar>
            </div>
          </vg-controls>
          <video id="media" preload="auto" [vgMedia]="media" #media [src]="performerDetail.welcomeVideo | safe" [autoplay]=autoPlay></video>
        </vg-player>
      </div>
      <div *ngIf="!isMe" class="flex-btns">
        <button type="button" class="btn btn-outline-primary"
          *ngIf="!isSubscribed" (click)="navigateToPay(false)">{{'subscribesYearly'|translate}}</button>
        <button type="button" class="btn btn-outline-primary"
          *ngIf="!isSubscribed" (click)="navigateToPay(true)">{{'subscribesMontly'|translate}}</button>
        <button type="button" class="btn btn-outline-primary" (click)="navigateToMessage(performerDetail._id)" >{{'Message' | translate}}</button>
        <button type="button" class="btn btn-outline-primary"
          *ngIf="isSubscribed && !isMe" (click)="onCancelSubscription()">
        {{'cancelSubscription'| translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="profile-container py-0">
  <div class="row">
    <div class="col-sm-4" >
      <div class="info-box7 l-bg-orange order-info-box7" *ngIf="isMe">
        <div class="info-box7-block">
          <h2>{{'subscribers' | translate}}</h2>
          <h2 class="mb-0 text-right">
            <mat-icon class="pull-left dashboard-cards-icon" style="font-size: 35px">subscriptions</mat-icon>
            <span>{{performerDetail?.totalSubscriber}}</span>
          </h2>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="info-box7 l-bg-green order-info-box7">
        <div class="info-box7-block">
          <h2>{{'videos' | translate}}</h2>
          <h2 class="mb-0 text-right">
            <mat-icon class="pull-left dashboard-cards-icon" style="font-size: 35px">video_library</mat-icon>
            <span>{{performerDetail?.totalVideo}}</span>
          </h2>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="info-box7 l-bg-purple order-info-box7">
        <div class="info-box7-block">
          <h2>{{'pictures' | translate}}</h2>
          <h2 class="mb-0 text-right">
            <mat-icon class="pull-left dashboard-cards-icon" style="font-size: 35px">photo_library</mat-icon>
            <span>{{performerDetail?.totalPhoto}}</span>
          </h2>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="profile-container py-0">
  <div class="card">
    <div class="row">
      <div class="col-sm-12">
        <mat-tab-group>
          <mat-tab [label]="'About'">
            <div class="tab-pane body active" id="about">
<!--              <mat-accordion>-->
<!--                <mat-expansion-panel>-->
<!--                  <mat-expansion-panel-header>-->
<!--                    <mat-panel-description>-->
<!--                      <p class="mb-0 text-default">{{'description'|translate}}</p>-->
<!--                    </mat-panel-description>-->
<!--                  </mat-expansion-panel-header>-->
<!--                  <div class="divide-line"></div>-->
<!--                  <p class="mb-0 text-default">{{performerDetail?.description}}</p>-->
<!--                </mat-expansion-panel>-->
<!--              </mat-accordion>-->
              <h3 class="mb-0">{{'description' | translate}}</h3>
              <div class="divide-line"></div>
              <p class="mb-0 text-default">{{performerDetail?.description}}</p>
            </div>
          </mat-tab>
          <mat-tab [label]="'Bio'">
            <div class="tab-pane body" id="bio">
<!--              <mat-accordion>-->
<!--                <mat-expansion-panel>-->
<!--                  <mat-expansion-panel-header>-->
<!--                    <h3 class="mb-0"> Basic Details</h3>-->
<!--                  </mat-expansion-panel-header>-->

<!--                  <div class="divide-line"></div>-->

<!--                  <div class="row">-->
<!--                    <div class="mb-0 col-lg-4 col-sm-6">-->
<!--                      <mat-form-field class="example-full-width" appearance="outline">-->
<!--                        <mat-label>{{'sex' | translate}}</mat-label>-->
<!--                        <input matInput readonly value="{{performerDetail?.sex}}">-->
<!--                      </mat-form-field>-->
<!--                    </div>-->
<!--                    <div class="mb-0 col-lg-4 col-sm-6">-->
<!--                      <mat-form-field class="example-full-width" appearance="outline">-->
<!--                        <mat-label>{{'eyes' | translate}}</mat-label>-->
<!--                        <input matInput readonly value="{{performerDetail?.eyes}}">-->
<!--                      </mat-form-field>-->
<!--                    </div>-->
<!--                    <div class="mb-0 col-lg-4 col-sm-6">-->
<!--                      <mat-form-field class="example-full-width" appearance="outline">-->
<!--                        <mat-label>{{'sexualPreference' | translate}}</mat-label>-->
<!--                        <input matInput readonly value="{{performerDetail?.sexualPreference}}">-->
<!--                      </mat-form-field>-->
<!--                    </div>-->
<!--                    <div class="mb-0 col-lg-4 col-sm-6">-->
<!--                      <mat-form-field class="example-full-width" appearance="outline">-->
<!--                        <mat-label>{{'ethnicity' | translate}}</mat-label>-->
<!--                        <input matInput readonly value="{{performerDetail?.ethnicity}}">-->
<!--                      </mat-form-field>-->
<!--                    </div>-->
<!--                    <div class="mb-0 col-lg-4 col-sm-6">-->
<!--                      <mat-form-field class="example-full-width" appearance="outline">-->
<!--                        <mat-label>{{'age' | translate}}</mat-label>-->
<!--                        <input matInput readonly value="{{performerDetail?.age}}">-->
<!--                      </mat-form-field>-->
<!--                    </div>-->
<!--                    <div class="mb-0 col-lg-4 col-sm-6">-->
<!--                      <mat-form-field class="example-full-width" appearance="outline">-->
<!--                        <mat-label>{{'languages' | translate}}</mat-label>-->
<!--                        <input matInput readonly value="{{performerDetail?.languages}}">-->
<!--                      </mat-form-field>-->
<!--                    </div>-->
<!--                    <div class="mb-0 col-lg-4 col-sm-6">-->
<!--                      <mat-form-field class="example-full-width" appearance="outline">-->
<!--                        <mat-label>{{'height' | translate}}</mat-label>-->
<!--                        <input matInput readonly value="{{performerDetail?.height}}">-->
<!--                      </mat-form-field>-->
<!--                    </div>-->
<!--                    <div class="mb-0 col-lg-4 col-sm-6">-->
<!--                      <mat-form-field class="example-full-width" appearance="outline">-->
<!--                        <mat-label>{{'homeTown' | translate}}</mat-label>-->
<!--                        <input matInput readonly value="{{performerDetail?.hometown}}">-->
<!--                      </mat-form-field>-->
<!--                    </div>-->
<!--                    <div class="mb-0 col-lg-4 col-sm-6">-->
<!--                      <mat-form-field class="example-full-width" appearance="outline">-->
<!--                        <mat-label>{{'weight' | translate}}</mat-label>-->
<!--                        <input matInput readonly value="{{performerDetail?.weight}}">-->
<!--                      </mat-form-field>-->
<!--                    </div>-->
<!--                    <div class="mb-0 col-lg-4 col-sm-6">-->
<!--                      <mat-form-field class="example-full-width" appearance="outline">-->
<!--                        <mat-label>{{'hair' | translate}}</mat-label>-->
<!--                        <input matInput readonly value="{{performerDetail?.hair}}">-->
<!--                      </mat-form-field>-->
<!--                    </div>-->
<!--                    <div class="mb-0 col-lg-4 col-sm-6">-->
<!--                      <mat-form-field class="example-full-width" appearance="outline">-->
<!--                        <mat-label>{{'bodyHair' | translate}}</mat-label>-->
<!--                        <input matInput readonly value="{{performerDetail?.publicHair}}">-->
<!--                      </mat-form-field>-->
<!--                    </div>-->
<!--                    <div class="mb-0 col-lg-4 col-sm-6">-->
<!--                      <mat-form-field class="example-full-width" appearance="outline">-->
<!--                        <mat-label>{{'footSize' | translate}}</mat-label>-->
<!--                        <input matInput readonly value="{{performerDetail?.bust}}">-->
<!--                      </mat-form-field>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </mat-expansion-panel>-->
<!--              </mat-accordion>-->
              <h3 class="mb-0"> Basic Details</h3>
              <div class="divide-line"></div>

              <div class="row">
                <div class="mb-0 col-lg-4 col-sm-6">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'sex' | translate}}</mat-label>
                    <input matInput readonly value="{{performerDetail?.sex}}">
                  </mat-form-field>
                </div>
                <div class="mb-0 col-lg-4 col-sm-6">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'eyes' | translate}}</mat-label>
                    <input matInput readonly value="{{performerDetail?.eyes}}">
                  </mat-form-field>
                </div>
                <div class="mb-0 col-lg-4 col-sm-6">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'sexualPreference' | translate}}</mat-label>
                    <input matInput readonly value="{{performerDetail?.sexualPreference}}">
                  </mat-form-field>
                </div>
                <div class="mb-0 col-lg-4 col-sm-6">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'ethnicity' | translate}}</mat-label>
                    <input matInput readonly value="{{performerDetail?.ethnicity}}">
                  </mat-form-field>
                </div>
                <div class="mb-0 col-lg-4 col-sm-6">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'age' | translate}}</mat-label>
                    <input matInput readonly value="{{performerDetail?.age}}">
                  </mat-form-field>
                </div>
                <div class="mb-0 col-lg-4 col-sm-6">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'languages' | translate}}</mat-label>
                    <input matInput readonly value="{{performerDetail?.languages}}">
                  </mat-form-field>
                </div>
                <div class="mb-0 col-lg-4 col-sm-6">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'height' | translate}}</mat-label>
                    <input matInput readonly value="{{performerDetail?.height}}">
                  </mat-form-field>
                </div>
                <div class="mb-0 col-lg-4 col-sm-6">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'homeTown' | translate}}</mat-label>
                    <input matInput readonly value="{{performerDetail?.hometown}}">
                  </mat-form-field>
                </div>
                <div class="mb-0 col-lg-4 col-sm-6">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'weight' | translate}}</mat-label>
                    <input matInput readonly value="{{performerDetail?.weight}}">
                  </mat-form-field>
                </div>
                <div class="mb-0 col-lg-4 col-sm-6">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'hair' | translate}}</mat-label>
                    <input matInput readonly value="{{performerDetail?.hair}}">
                  </mat-form-field>
                </div>
                <div class="mb-0 col-lg-4 col-sm-6">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'bodyHair' | translate}}</mat-label>
                    <input matInput readonly value="{{performerDetail?.publicHair}}">
                  </mat-form-field>
                </div>
                <div class="mb-0 col-lg-4 col-sm-6">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{'footSize' | translate}}</mat-label>
                    <input matInput readonly value="{{performerDetail?.bust}}">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>

<div class="profile-container py-0">
  <div class="card">
    <mat-tab-group class="home-tab-group" #tabGroup>
      <mat-tab label="{{'videos'|translate}}">
        <app-list-view [performerId]="isMe ? userId : performerId"></app-list-view>
      </mat-tab>
      <mat-tab label="{{'Albums'|translate}}">
        <app-albums [performerId]="isMe ? userId : performerId"></app-albums>
      </mat-tab>
      <mat-tab label="{{'store'|translate}}">
        <app-model-store [performerId]="isMe ? userId : performerId"></app-model-store>
      </mat-tab>
      <mat-tab label="{{'saleVideos'|translate}}">
        <app-model-sale-videos [performerId]="isMe ? userId : performerId"></app-model-sale-videos>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
