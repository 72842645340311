import {AfterViewInit, Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {AppComponentBase} from '../shared/AppComponentBase';
import {ISubscription} from 'rxjs-compat/Subscription';

import * as $ from 'jquery';

@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.scss']
})
export class LayoutsComponent extends AppComponentBase implements OnInit, OnDestroy, AfterViewInit {

  private loggedInSubscription: ISubscription;
  public isAuthenticated = false;
  public role: string;
  constructor(private inject: Injector) {
    super(inject);
    this.loggedInSubscription = this.loginService.isUserLoggedIn.subscribe(res => {
      this.isAuthenticated = this.authService.isAuthenticated();
      $( () => {
        $('app-sidebar').mouseenter(() => {
          $('.sidbar-layout').addClass('expanded');
        });
        $('app-sidebar').mouseleave(() => {
          $('.sidbar-layout').removeClass('expanded');
        });
      });
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    $( () => {
      $('app-sidebar').mouseenter(() => {
        $('.sidbar-layout').addClass('expanded');
      });
      $('app-sidebar').mouseleave(() => {
        $('.sidbar-layout').removeClass('expanded');
      });
    });
  }

  ngOnDestroy(): void {
    this.loggedInSubscription.unsubscribe();
  }

}
