<div *ngIf="data.action==='delete'">
  <h2 mat-dialog-title>{{'confirmDelete' | translate}}</h2>
  <div class="divide-line"></div>
  <div mat-dialog-content>
    <p>{{'deleteMessage' | translate}}</p>
  </div>
  <div mat-dialog-actions class="button-action form-buttons">
    <button mat-button
            (click)="cancelButton()" class="cancel" id="cancel">{{'cancel' | translate}}</button>
    <button mat-raised-button (click)="submitButton()" color="primary" id="submit">{{'delete' | translate}}
    </button>
  </div>
</div>
<div *ngIf="data.action==='untag'">
  <h2 mat-dialog-title>{{'confirmUntag' | translate}}</h2>
  <div class="divide-line"></div>
  <div mat-dialog-content>
    <p>{{'untagMessage' | translate}}</p>
  </div>
  <div mat-dialog-actions class="button-action form-buttons">
    <button mat-button
            (click)="cancelButton()" class="cancel" id="cancel">{{'cancel' | translate}}</button>
    <button mat-raised-button (click)="submitButton()" color="primary" id="submit">{{'untag' | translate}}
    </button>
  </div>
</div>
<div *ngIf="data.action ==='cancelSubscription'">
  <h2 mat-dialog-title>{{'confirmCancelSubscription' | translate}}</h2>
  <div class="divide-line"></div>
  <div mat-dialog-content>
    <p>
        By doing this you will stop supporting this model
        We are sad to hear that you do no longer want to support the model.
        We suggest that you wait until the day before the next billing cycle so you don't loose access to content.
        Warning: if you unsubscribe now, you will loose immediate access
        For more info please contact our support team.
        Thanks
    </p>
    <p style="color: red; font-size: 13px;">Be ensured your subcription also has been cancelled on Paypal.</p>
    <ul>
      <li>1.Log in to your PayPal account.</li>
      <li>2.Click Settings near the top of the page.</li>
      <li>3.Click Payments.</li>
      <li>4.Click Manage pre-approved payments.</li>
      <li>5.Click Cancel or Cancel automatic billing and follow the instructions.</li>
    </ul>
  </div>
  <div mat-dialog-actions class="button-action form-buttons">
    <button mat-button
            (click)="cancelButton()" class="cancel" id="cancel">{{'cancel' | translate}}</button>
    <button mat-raised-button (click)="submitButton()" color="primary" id="submit">{{'ok' | translate}}
    </button>
  </div>
</div>
<div *ngIf="data.action==='userId'">
  <h2 mat-dialog-title>{{'pleaseBeUploadVerificationIDs' | translate}}</h2>
  <div class="divide-line"></div>
  <div mat-dialog-content>
    <br/>
    <div class="row">
      <form>
        <mat-form-field class="col-lg-12" appearance="outline">
          <mat-label>{{'selectPhoto' | translate}}</mat-label>
          <ngx-mat-file-input
            placeholder="Multiple inputs" multiple
            (change)="onSelectFile($event)"
            accept="image/*"></ngx-mat-file-input>
          <mat-icon matSuffix>folder</mat-icon>
        </mat-form-field>
      </form>
    </div>
    <div *ngIf="bulkUploadImages.length" class="row">
      <div *ngFor="let image of bulkUploadImages; let i = index" class="col-3">
        <img src="{{image.url}}">
        <i class="fa fa-trash icon-delete" (click)="remove(i)"></i>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="example-button-row submit-btn">
    <button mat-button style="align: center"
            (click)="cancelButton()" class="cancel" id="cancel">{{'cancel' | translate}}</button>
    <button mat-raised-button (click)="onSubmit()" color="primary" id="submit">{{'submit' | translate}}
    </button>
  </div>
</div>
<div *ngIf="data.action==='modelToUser'">
  <h2 mat-dialog-title>{{'confirmModelToUser' | translate}}</h2>
  <div class="divide-line"></div>
  <div *ngIf="data.role === 'model'" mat-dialog-content>
    <p>{{'modelToUserMessage' | translate}}</p>
  </div>
  <div *ngIf="data.role === 'admin'" mat-dialog-content>
    <p>{{'modelToUserAdminMessage' | translate}}</p>
  </div>
  <div mat-dialog-actions class="button-action form-buttons">
    <button mat-button
            (click)="cancelButton()" class="cancel" id="cancel">{{'cancel' | translate}}</button>
    <button mat-raised-button (click)="submitButton()" color="primary" id="submit">{{'confirm' | translate}}
    </button>
  </div>
</div>
<div *ngIf="data.action==='userToModel'">
  <h2 mat-dialog-title>{{'confirmUserToModel' | translate}}</h2>
  <div class="divide-line"></div>
  <div *ngIf="data.role === 'user'" mat-dialog-content>
    <p>{{'userToModelMessage' | translate}}</p>
  </div>
  <div *ngIf="data.role === 'admin'" mat-dialog-content>
    <p>{{'userToModelAdminMessage' | translate}}</p>
  </div>
  <div mat-dialog-actions class="button-action form-buttons">
    <button mat-button
            (click)="cancelButton()" class="cancel" id="cancel">{{'cancel' | translate}}</button>
    <button mat-raised-button (click)="submitButton()" color="primary" id="submit">{{'confirm' | translate}}
    </button>
  </div>
</div>
