import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API} from '../utils/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class ModelTaggedVideoService {

  constructor(private http: HttpClient) {
  }

  getTaggedVideoList(data): Observable<any> {
    return this.http.get(API.modelTaggedVideoList + '?filter=' + data.filter + '&page=' + data.page + '&performerId=' +
      data.performerId + '&take=' + data.take + '&sort=' + data.sort + '&order=' + data.order + '&keyword=' + data.keyword);
  }

  getVideoList(data): Observable<any> {
    if (data.isMe) {
      return this.http.get(API.modelTaggedVideoList + '?isSaleVideo=' + data.isSaleVideo + '&page=' + data.page +
        '&performerId=' + data.performerId + '&sort=' + data.sort + '&status=' + data.status + '&take=' + data.take + '&home=1');
    }
    else {
      return this.http.get(API.modelTaggedVideoList + '?isSaleVideo=' + data.isSaleVideo + '&page=' + data.page +
        '&performerId=' + data.performerId + '&sort=' + data.sort + '&status=' + data.status + '&take=' + data.take + '&test=test&home=1');
    }
  }

  doLike(data): Observable<any> {
    return this.http.put(API.modelVideoLike, data);
  }
  updateVideo(id, item): Observable<any> {
    return this.http.put(API.updateVideo + id, item);
  }
}
