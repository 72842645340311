import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API} from '../utils/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class AllMoviesServiceService {

  constructor(private http: HttpClient) {
  }
  getAllModelsService(): Observable<any>{
    return this.http.get(API.getAllMOdel);
  }
  getAllMoviesService(data): Observable<any> {
    return this.http.get(API.modelRelatedVideo + '?categoryId=' + data.categoryId + '&keyword=' + data.keyword + '&limit=' + data.limit
    + '&offset=' + data.offset + '&order=' + data.order + '&performer=' + data.performer + '&sort=sort');
  }
  getAllMoviesCountService(data): Observable<any> {
    return this.http.get(API.modelRelatedVideo + '?categoryId=' + data.categoryId + '&keyword=' + data.keyword +
    '&performer=' + data.performer + '&sort=sort');
  }
  getRecentMoviesService(data): Observable<any>{
    return this.http.get(API.modelRelatedVideo + '?categoryId=' +  data.categoryId + '&limit=' + data.limit + '&offset=' + data.offset +
    '&keyword=' + data.keyword + '&performer=' +  data.performer + '&order=' + data.order + '&sort=sort&type=recent');
  }
  getRecentCountService(data): Observable<any> {
    return this.http.get(API.modelRelatedVideo + '?categoryId=' + data.categoryId + '&keyword=' + data.keyword +
      '&performer=' + data.performer + '&sort=sort&type=recent');
  }
  getUpcomingMoviesService(data): Observable<any>{
    return this.http.get(API.modelRelatedVideo + '?categoryId=' +  data.categoryId + '&limit=' + data.limit + '&offset=' + data.offset +
      '&keyword=' + data.keyword + '&performer=' +  data.performer + '&order=' + data.order + '&sort=sort&type=upcoming');
  }
  UpcomingCountService(data): Observable<any> {
    return this.http.get(API.modelRelatedVideo + '?categoryId=' + data.categoryId + '&keyword=' + data.keyword +
      '&performer=' + data.performer + '&sort=sort&type=recent');
  }
  getModelDetails(): Observable<any> {
    return this.http.get(API.modelDropdown );
  }
}
