<div class="body">
  <div class="album-row">
    <div *ngFor="let album of albums" class="album-card">
      <div id="aniimated-thumbnials">
        <div>
          <mat-card (click)="openImageViewer(album)">
            <mat-card-header>
              <mat-card-title style="cursor: pointer" matTooltip="{{album?.name}}">{{album?.name}}</mat-card-title>
            </mat-card-header>
            <div class="example-full-width card-container" style="cursor: pointer">
              <div *ngFor="let image of album?.photos; let i = index">
                <div *ngIf="i < 4">
                  <img *ngIf="image?.imageFullPath && !image?.imageFullPath.includes('.svg')" src="{{image?.imageThumbPath | checkImage}}">
                  <img *ngIf="image?.imageFullPath && image?.imageFullPath.includes('.svg')" src="{{image?.imageFullPath | checkImage}}">
                </div>
              </div>
            </div>
          </mat-card>
        </div>
        <p *ngIf="!albums.length" class="no-record-found">{{'noRecordFound' | translate}}</p>
      </div>
    </div>
  </div>
</div>
