import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API} from '../utils/constants/constant';
@Injectable({
  providedIn: 'root'
})
export class AdminStoreService {

  constructor(private http: HttpClient) { }
  getProductsList(filter): Observable<any> {
    return this.http.get(API.productDetail + '?keyword=' + filter.keyword + '&page=' +
      filter.page + '&take=' + filter.take + '&order=' + filter.order + '&sort=' + filter.sort);
  }
  delete(id): Observable<any>{
    return this.http.delete(API.deleteProduct + '?id=' + id );
  }
  getProductCategoryList(data): Observable<any> {
   return this.http.get(API.modelProductCatagories + '?string=' + data);
  }
  getProductDetail(id): Observable<any> {
    return this.http.get(API.singleProductDetail + '?id=' + id);
  }
  createProduct(data): Observable<any>{
    return this.http.post(API.createProduct , data);
  }
  updateProduct(id, data): Observable<any>{
    return this.http.put( API.updateProduct + id, data );
  }
  getModelDropdownList(): Observable<any> {
    return this.http.get(API.modelDropdown);
  }
}
