<div class="body">
  <div class="margin-set">
    <div *ngIf="products.length">
      <div class="row">
        <div *ngFor="let product of products" class="col-sm-6 col-lg-4 col-xl-3">
          <mat-card>
            <mat-card-header>
              <img mat-card-avatar
                   src="{{performerProfile | checkImage}}">
              <mat-card-title matTooltip="{{product?.name}}" style="cursor: pointer">{{product?.name}}</mat-card-title>
              <mat-card-subtitle>{{product?.dateDuration}}</mat-card-subtitle>
            </mat-card-header>
            <div class="example-full-width model_image" style="cursor: pointer">
              <img class="orginal-img" [src]="product?.imageMediumPath | checkImage">
              <img class="image-background" [src]="product?.imageMediumPath | checkImage">
            </div>
            <mat-card-actions class="mb-0">
              <div fxLayout="row" class="flex-part">
                <div class="align-left">
                  <label style="color:black" *ngIf="product?.pricePromotion==0">
                    {{product.price|currency}}
                  </label>
                  <label *ngIf="product?.pricePromotion!=0">
                    {{product?.pricePromotion|currency}}
                  </label>
                </div>
                <div class="align-right">
                  <button mat-raised-button color="primary" *ngIf="product?.quantity && role ==='user'"
                          (click)="onBuy(product)" matTooltip="{{'buy' | translate}}">
                    {{'buy'| translate}}
                  </button>
                  <button mat-raised-button color="warn" *ngIf="product?.quantity == 0" matTooltip="sold out">
                    {{'soldOut'|translate}}
                  </button>
                </div>
              </div>
            </mat-card-actions>
          </mat-card>
          <div class="text-center" *ngIf="totalProduct > numberOfShowingProduct">
            <button mat-raised-button (click)="loadMore()" color="warn" value="Load More"></button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!products.length">
      <p class="no-record-found">{{'noRecordFound' | translate}}</p>
    </div>
  </div>
</div>
