import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {AppComponentBase} from '../../../../shared/AppComponentBase';
import {EStatusCode} from '../../../../services/utils/constants/response-handler';
import {NgxGaleryViewerComponent} from '../../../../shared/ngx-galery-viewer/ngx-galery-viewer.component';
import {COMMON} from '../../../../services/utils/constants/constant';
import {ISubscription} from 'rxjs-compat/Subscription';

@Component({
  selector: 'app-albums',
  templateUrl: './albums.component.html',
  styleUrls: ['./albums.component.scss']
})
export class AlbumsComponent extends AppComponentBase implements OnInit, OnDestroy {

  @Input() private performerId: string;
  private userId: string;
  private isMe: boolean;
  private performerProfileSubscription: ISubscription;
  public albums = [];

  constructor(private injector: Injector) {
    super(injector);
    this.performerProfileSubscription = this.modelHomeService.performerProfileListener.subscribe(res => {
      if (res) {
        this.userId = localStorage.getItem('performerId');
        this.performerId = this.modelHomeService.performerId;
        this.isMe = this.userId === this.performerId;
        this.getAlbums();
      }
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem('role') !== 'user') {
      this.userId = localStorage.getItem('performerId');
      this.isMe = this.userId === this.performerId;
    }
    // this.getAlbums();
  }

  ngOnDestroy(): void {
    this.performerProfileSubscription.unsubscribe();
  }

  openImageViewer(album): void {
    // this.getPhotos(album);
    const dialogRef = this.dialog.open(NgxGaleryViewerComponent, {
      data: {
        action: 'delete',
        performerId: this.performerId,
        albumId: album._id,
        albumName: album.name,
        gallery: album.photos
      },
      width: COMMON.IMAGE_VIEWER_WIDTH,
      height: COMMON.IMAGE_VIEWER_HEIGHT
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('Submmited');
      }
    });
  }

  getAlbums(): void {
    if (this.performerId) {
      this.modelAlbums.getAlbums({
        performerId: this.performerId,
        isMe: this.isMe
      }).subscribe(res => {
        if (res.statusCode === EStatusCode.OK) {
          this.albums = (res.data);
        } else {
          console.log(res.message);
        }
      }, error => {
      });
    }
  }

  getPhotos(data): void {
    this.modelAlbums.getPhotos({
      albumId: data._id,
      performerId: this.performerId,
      take: 1000
    }).subscribe(res => {
      if (res.statusCode === EStatusCode.OK) {
        if (res.data && res.data.items) {
          // const dialogRef = this.dialog.open(NgxGaleryViewerComponent, {
          //  data : {
          //     action: 'delete',
          //     gallery: res.data.items,
          //    albumName: data.name
          //   },
          //   width: COMMON.IMAGE_VIEWER_WIDTH,
          //   height: COMMON.IMAGE_VIEWER_HEIGHT
          // });
          // dialogRef.afterClosed().subscribe((result) => {
          //   if (result) {
          //     console.log('Submmited');
          //   }
          // });
        } else {
          // const dialogRef = this.dialog.open(NgxGaleryViewerComponent, {
          //   data: {
          //     action: 'delete',
          //     performerId: this.performerId,
          //     gallery: res.items
          //   },
          //   width: COMMON.IMAGE_VIEWER_WIDTH,
          //   height: COMMON.IMAGE_VIEWER_HEIGHT
          // });
          // dialogRef.afterClosed().subscribe((result) => {
          //   if (result) {
          //     console.log('Submmited');
          //   }
          // });
        }
      } else {
      }
    }, error => {
    });
  }


}
