import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {API} from '../constants/constant';
import {LoginService} from "../../login.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    private http: HttpClient,
    private loginService: LoginService
  ) {
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem('token');
  }

  logoutForMalformedToken(): void {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  logout(): void {
    this.logoutForMalformedToken();
    /*if ((window as any).fcWidget) {
      (window as any).fcWidget.hide();
    }*/
  }

  updateIP(id): any {
    this.http.get('https://ipinfo.io/json?token=ipinfo.io').subscribe((response: any) => {
      if (response.ip) {
        const reqData = {
          userId: id,
          ipDetails: {
            userId: id,
            ip: response.ip,
            city: response.city,
            country: response.country,
            loc: response.loc,
            postal: response.postal,
            region: response.region,
            timezone: response.timezone
          }
        };
        this.http.post(API.updateIp, reqData).subscribe((res: any) => {
          if (res.data.statusCode !== 200) {
            console.log(res.data.error);
          }
        });
      }
    });
  }

}
