import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AppComponentBase} from '../AppComponentBase';
import {EStatusCode} from '../../services/utils/constants/response-handler';

@Component({
  selector: 'app-common-dialog-model',
  templateUrl: './common-dialog-model.component.html',
  styleUrls: ['./common-dialog-model.component.scss']
})
export class CommonDialogModelComponent extends AppComponentBase implements OnInit {
  bulkUploadImages: any[] = [];
  public images: any[] = [];
  private userId: any;

  constructor(
    public dialogRef: MatDialogRef<CommonDialogModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private inject: Injector
  ) {
    super(inject);
  }

  ngOnInit(): void {
    this.userId = localStorage.getItem('userId');
  }

  submitButton(): void {
    this.dialogRef.close(true);
  }

  cancelButton(): void {
    this.dialogRef.close(false);
  }

  onSelectFile(event): void {
    if (event.target.files && event.target.files[0]) {
      const filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        const reader = new FileReader();
        reader.onload = (fileEvent: any) => {
          this.bulkUploadImages.push({
            url: fileEvent.target.result,
            showExtra: false,
            name: event.target.files[i].name,
            description: '',
            album: [],
            metaKeywords: '',
            metaTitle: '',
            metaDescription: '',
            metaSort: 0,
            status: 'active',
          });
        };
        reader.readAsDataURL(event.target.files[i]);
        this.images.push(event.target.files[i]);
      }
    }
  }

  remove(i): void {
    this.bulkUploadImages.splice(i, 1);
    this.images.splice(i, 1);
  }

  onSubmit(): void {
    if (this.images.length >= 2 && this.images.length <= 5) {
      const formData = new FormData();
      this.images.forEach(file => {
        formData.append('file[]', file);
      });
      formData.append('userId', this.userId);
      this.userHomeService.updateUserIds(formData).subscribe(res => {
        if (res.statusCode === EStatusCode.OK) {
          this.submitButton();
        } else {
          this.toastr.success(res.message);
        }
      }, err => {
        console.log(err);
      });
    } else {
      this.images.length <= 2 ?
        this.toastr.error('Minimum 2 files required') :
        this.toastr.error('Maximum 5 files can be upload');
    }
  }

}
