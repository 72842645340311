import {RouteInfo} from './sidebar.metadata';


export const ROUTES: RouteInfo[] = [
  {
    path: '/model/home',
    title: 'Home',
    icon: 'fas fa-home',
    class: '',
    groupTitle: false
  },
  {
    path: '/model/account-information',
    title: 'Account Information',
    icon: 'fas fa-user-alt',
    class: '',
    groupTitle: false
  },
  {
    path: '/model/list',
    title: 'Models',
    icon: 'fas fa-user-friends',
    class: '',
    groupTitle: false
  },
  {
    path: '/model/video-manager',
    title: 'Videos Manager',
    icon: 'fas fa-camera',
    class: '',
    groupTitle: false
  },
  {
    path: '/model/photo-manager',
    title: 'Photos Manager',
    icon: 'fas fa-images',
    class: '',
    groupTitle: false
  },
  {
    path: '/model/product-manager',
    title: 'Products Manager',
    icon: 'fas fa-shopping-cart',
    class: '',
    groupTitle: false
  },
  {
    path: '/model/calender-event',
    title: 'Calendar Events Manager',
    icon: 'fas fa-calendar',
    class: '',
    groupTitle: false
  },
  {
    path: '/model/mycalender',
    title: 'My Calendar',
    icon: 'fas fa-calendar-alt',
    class: '',
    groupTitle: false
  },
  {
    path: '/model/earnings',
    title: 'Earnings',
    icon: 'fas fa-dollar-sign',
    class: '',
    groupTitle: false
  },
  {
    path: '/model/payout-request',
    title: 'Payout Requests',
    icon: 'fas fa-money-bill',
    class: '',
    groupTitle: false
  },
  {
    path: '/model/messages',
    title: 'Messages',
    icon: 'fas fa-comment-alt',
    class: '',
    groupTitle: false
  },
  {
    path: '/model/notifications',
    title: 'Notification',
    icon: 'fas fa-bell',
    class: '',
    groupTitle: false
  },
  {
    path: '/model/statistics',
    title: 'Statistics',
    icon: 'fas fa-chart-bar',
    class: '',
    groupTitle: false
  },
  {
    path: '/model/tagged-videos',
    title: 'Tagged Videos',
    icon: 'fas fa-check',
    class: '',
    groupTitle: false
  },
  {
    path: '/model/change-password',
    title: 'Change password',
    icon: 'fas fa-key',
    class: '',
    groupTitle: false
  }
];
export const ROUTESAdmin: RouteInfo[] = [
  {
    path: '/admin/dashboard',
    title: 'Dashboard',
    icon: ' icon-home',
    class: '',
    groupTitle: false
  },
  {
    path: '/admin/store/list',
    title: 'Store',
    icon: ' icon-basket',
    class: '',
    groupTitle: false
  },
  {
    path: '/admin/product-category/list',
    title: 'Product category',
    icon: 'icon-basket-loaded',
    class: '',
    groupTitle: false
  },
  {
    path: '/admin/coupon/list',
    title: 'Coupons',
    icon: ' icon-diamond',
    class: '',
    groupTitle: false
  },
  {
    path: '/admin/performer/list',
    title: 'Models',
    icon: 'icon-user-female',
    class: '',
    groupTitle: false
  },
  {
    path: '/admin/video/list',
    title: 'Videos',
    icon: 'icon-camrecorder',
    class: '',
    groupTitle: false
  },
  {
    path: '/admin/category/list',
    title: 'Videos Categories',
    icon: 'icon-grid',
    class: '',
    groupTitle: false
  },
  {
    path: '/admin/photo/list',
    title: 'Photos',
    icon: 'icon-picture',
    class: '',
    groupTitle: false
  },
  {
    path: '/admin/banner/list',
    title: 'Banners',
    icon: 'icon-puzzle',
    class: '',
    groupTitle: false
  },
  {
    path: '/admin/payout/list',
    title: 'Requests Payout',
    icon: 'icon-credit-card',
    class: '',
    groupTitle: false
  },
  {
    path: '/admin/order/list',
    title: 'Orders',
    icon: '  icon-calendar',
    class: '',
    groupTitle: false
  },
  {
    path: '/admin/member/list',
    title: 'Users',
    icon: 'icon-people',
    class: '',
    groupTitle: false
  },
  {
    path: '/admin/subscriber/list',
    title: 'Subscribers',
    icon: ' icon-user-following',
    class: '',
    groupTitle: false
  },
  {
    path: '/admin/earning/list',
    title: 'Earning Stats',
    icon: ' icon-paypal',
    class: '',
    groupTitle: false
  },
  {
    path: '/admin/page/list',
    title: 'Page',
    icon: 'icon-book-open',
    class: '',
    groupTitle: false
  },
  {
    path: '/admin/setting/list',
    title: 'Settings',
    icon: 'icon-settings',
    class: '',
    groupTitle: false
  }

  ];

export const ROUTESUser: RouteInfo[] = [
  {
    path: '/user/model/list',
    title: 'Models',
    icon: ' fa fa-users',
    class: '',
    groupTitle: false
  },
  {
    path: '/user/account-information',
    title: 'Account information',
    icon: 'far fa-user-circle',
    class: '',
    groupTitle: false
  },
  {
    path: '/user/favorites',
    title: 'Favorites',
    icon: 'ti-heart',
    class: '',
    groupTitle: false
  },
  {
    path: '/user/watch-later',
    title: 'Watch later',
    icon: 'far fa-clock',
    class: '',
    groupTitle: false
  },
  {
    path: '/user/payment-history',
    title: 'Payment history',
    icon: 'fas fa-donate',
    class: '',
    groupTitle: false
  },
  {
    path: '/user/cancellation-history',
    title: 'Cancellation ',
    icon: 'far fa-file-alt',
    class: '',
    groupTitle: false
  },
  {
    path: '/user/chat/messages',
    title: 'Messages',
    icon: 'icon-bubbles',
    class: '',
    groupTitle: false
  },
  {
    path: '/user/cart',
    title: 'Cart',
    icon: 'icon-basket-loaded',
    class: '',
    groupTitle: false
  },
  {
    path: '/user/contact',
    title: 'Contact',
    icon: 'far fa-address-card',
    class: '',
    groupTitle: false
  },
  {
    path: '/user/notifications',
    title: 'Notifications',
    icon: 'ti-bell',
    class: '',
    groupTitle: false
  },
   {
    path: '/user/change-password',
    title: 'Change password',
    icon: 'ti-lock',
    class: '',
    groupTitle: false
  }
];
