import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API } from '../utils/constants/constant';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ModelPayoutRequestService {

  constructor(private http: HttpClient) { }

  getPayoutRequestList(data): Observable<any> {
    return this.http.get(API.modelPayoutRequest + '?page=' + data.page + '&performerId=' +
      data.performerId + '&take=' + data.take + '&order=' + data.order + '&sort=' + data.sort + '&keyword=' + data.keyword);
  }

  getPayoutRequestDetail(id): Observable<any> {
    return this.http.get(API.modelPayoutRequestDetail + id);
  }

  createPayoutRequest(payoutDetail): Observable<any> {
    return this.http.post(API.modelPayoutRequestDetail, payoutDetail);
  }

  getEarningStats(date): Observable<any> {
    return this.http.get(API.earningStats + '?dateFrom=' + date.from + '&dateTo=' + date.to);
  }

  deletePayoutRequest(id): Observable<any> {
    return this.http.delete(API.updateViewDelPayout + id);
  }

  updatePayoutRequest(id, payoutDetail): Observable<any> {
    return this.http.put(API.updateViewDelPayout + id, payoutDetail);
  }
}
