import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {API} from '../utils/constants/constant';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminUserService {

  constructor(private http: HttpClient) {
  }

  exportUsers(): Observable<any> {
    return this.http.get(API.exportPerformerCsv);
  }

  getUsersList(filter): Observable<any> {
    return this.http.get(API.updateDetails + '?keyword=' + filter.keyword + '&page=' +
      filter.page + '&limit=' + filter.limit + '&order=' + filter.order + '&sort=' +
      filter.sort + '&offset=' + filter.offset + '&event=' + filter.event);
  }
  getUserDashboardsList(filter): Observable<any> {
    return this.http.get(API.updateDetails + '?keyword=' + filter.keyword + '&limit=' + filter.limit + '&order=' + filter.order + '&sort=' +
      filter.sort + '&offset=' + filter.offset );
  }

  deleteUser(id): Observable<any> {
    return this.http.delete(API.deleteUser + id);
  }

  createUser(data): Observable<any> {
    return this.http.post(API.createUser, data);
  }

  updateUser(data, id): Observable<any> {
    return this.http.put(API.updateUser + id, data);
  }

  getDetails(id): Observable<any> {
    return this.http.get(API.updateUser + id);
  }

  getUserDropdownList(): Observable<any> {
    return this.http.get(API.usersDropdown);
  }

  convertAsModel(convertUser): Observable<any> {
    return this.http.put(API.convertToModel, {userId: convertUser, convertUser});
  }

  loginAsUser(data): Observable<any> {
    return this.http.post(API.loginAsUser, data);
  }

  convertAsUser(convertPerformer): Observable<any> {
    return this.http.put(API.convertToUser, {performerId: convertPerformer, convertPerformer});
  }
}
