import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {API} from '../utils/constants/constant';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminCouponsService {

  constructor(private http: HttpClient) { }

  getCouponsList(data): Observable<any> {
    return this.http.get(API.getCouponsList + '?take=' + data.take + '&page=' + data.page + '&order=' + data.order
    + '&keyword=' + data.keyword + '&sort=' + data.sort);
  }
  deleteCoupon(id): Observable<any> {
    return this.http.delete(API.viewDeleteUpdate + id);
  }
  getCouponDetail(id): Observable<any> {
   return this.http.get(API.viewDeleteUpdate + id);
  }
  getTotalProducts(data): Observable<any> {
    return this.http.get(API.productDetail + '?string=' + data);
  }
  createCoupon(data): Observable<any> {
    return this.http.post(API.createCoupon, data);
  }
  updateCoupon(id, data): Observable<any> {
    return this.http.put(API.viewDeleteUpdate + id, data);
  }
}
