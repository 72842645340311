import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url, type?: string): any {
    if (url && type === 'clouldFlare') {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } else if (url) {
      return this.sanitizer.bypassSecurityTrustUrl(url);
    } else {
      return '/assets/images/noImageThumb1.jpg';
    }
  }
}
