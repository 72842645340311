import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API} from './utils/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class VideoManagerService {

  constructor(private http: HttpClient) {
  }

  getVideosListService(data): Observable<any> {
    if (data.startDate && data.endDate) {
      return this.http.get(API.modelVideoList + '?keyword=' + data.keyword + '&limit=' + data.limit + '&offset=' + data.offset +
        '&order=' + data.order + '&performerId=' + data.performerId + '&sort=' + data.sort + '&status=' + data.status +
        '&startDate=' + data.startDate + '&endDate=' + data.endDate);
    } else {
      return this.http.get(API.modelVideoList + '?keyword=' + data.keyword + '&limit=' + data.limit + '&offset=' + data.offset +
        '&order=' + data.order + '&performerId=' + data.performerId + '&sort=' + data.sort + '&status=' + data.status);
    }
  }

  getTags(data): Observable<any> {
    return this.http.get(API.modelTags + '?performerId=' + data.performerId);
  }

  uploadVideo(data: FormData): Observable<any> {
    return this.http.post(API.modelRelatedVideo, data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  getVideo(id: string): Observable<any> {
    return this.http.get(API.modelRelatedVideo + id);
  }

  deleteVideo(id): Observable<any> {
    return this.http.delete(API.modelRelatedVideo + id);
  }

  updateVideoStatus(id, data): Observable<any> {
    return this.http.put(API.modelVideoList + '/' + id, data);
  }

  updateVideo(id, data): Observable<any> {
   return this.http.put(API.modelVideoList + '/' + id , data, {
     reportProgress: true,
     observe: 'events'
   });
  }
  getVideoCount(data): Observable<any> {
    if (data.startDate && data.endDate) {
      return this.http.get(API.modelVideoList + '?keyword=' + data.keyword + '&performerId=' + data.performerId +
        '&status=' + data.status + '&startDate=' + data.startDate + '&endDate=' + data.endDate );
    }
    else{
      return this.http.get(API.modelVideoList + '?keyword=' + data.keyword + '&performerId=' + data.performerId +
        '&status=' + data.status );
    }
  }

  updateCloudFlareStatus(): Observable<any> {
    return this.http.get(API.updateCloudFlareStatus);
  }
}
