import {Component, Injector, OnInit, Input, OnDestroy} from '@angular/core';
import {AppComponentBase} from '../../../../shared/AppComponentBase';
import {EStatusCode} from '../../../../services/utils/constants/response-handler';
import {ResponseMessages} from '../../../../services/utils/constants/constant';

@Component({
  selector: 'app-model-store',
  templateUrl: './model-store.component.html',
  styleUrls: ['./model-store.component.scss']
})
export class ModelStoreComponent extends AppComponentBase implements OnInit, OnDestroy {
  public products = [];
  public performerProfile: string;
  public  numberOfShowingProduct = 0;
  public totalProduct = 0;

  private performerProfileSubscription: any;
  @Input() private  performerId: string;
  private  page = 1;
  private status = 'active';

  constructor(private inject: Injector) {
    super(inject);
    this.performerProfileSubscription = this.modelHomeService.performerProfileListener.subscribe(res => {
      if (res) {
        this.performerProfile = this.modelHomeService.performerProfile;
        this.performerId = this.modelHomeService.performerId;
        this.getProducts();
      }
    });
  }

  ngOnInit(): void {
    // this.getProducts();
  }

  ngOnDestroy(): void {
    this.performerProfileSubscription.unsubscribe();
  }

  getProducts(): void {
    if (this.performerId) {
      const data = {
        page: this.page,
        performerId: this.performerId,
        status: this.status,
        take: 12
      };
      this.productService.getStoreProducts(data).subscribe(res => {
        if (res.statusCode === EStatusCode.OK) {
          this.products = this.products.concat(res.items);
          this.totalProduct = res.count;
          this.numberOfShowingProduct = this.products.length;
        }
      }, error => {
        console.log(error);
      });
    }
  }

  loadMore(): void {
    this.page++;
    this.getProducts();
  }

  onBuy(product: any): void {
   let  cardProduct = JSON.parse(localStorage.getItem('cardProduct'));
   let alreadyBuy = false;
   if (cardProduct && cardProduct.length) {
     cardProduct.forEach(product1 => {
       if (product1._id === product._id) {
         alreadyBuy = true;
       }
     });
   }
   if (cardProduct && cardProduct.length) {
     if (cardProduct[0].performerId !== product.performerId) {
       this.toastr.error(ResponseMessages.notPossibleToAdd);
       return;
     }
   }
   if (!alreadyBuy) {
     if (!cardProduct) {
       cardProduct = [];
     }
     product.goToBuy = 1;
     product.buyingPrice = product.price;
     cardProduct.push(product);
     localStorage.setItem('cardProduct', JSON.stringify(cardProduct));
     this.toastr.success(ResponseMessages.cardAdded);
     this.productService.cardBuyEvent.next(true);
   }else {
     this.toastr.error(ResponseMessages.alreadyAdded);
   }
  }
}
