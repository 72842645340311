import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SafePipe} from './pipes/safePipe';
import {CheckImage} from './pipes/checkImage';
import {CheckAvatar} from './pipes/checkAvatar';
import {LimitToPipe} from './pipes/limit-to-pipe';
import {DateAgo} from './pipes/dateAgo';
import {DurationPipe} from './pipes/duration.pipe';
import {ReversePipe} from './pipes/reverse.pipe';
import {ArrayStringFilterByPipe} from './pipes/arrayStringFilterBy';
import {StripHtmlPipe} from './pipes/strip-pipe';

@NgModule({
  declarations: [
    SafePipe,
    CheckImage,
    CheckAvatar,
    LimitToPipe,
    DateAgo,
    DurationPipe,
    ReversePipe,
    ArrayStringFilterByPipe,
    StripHtmlPipe
  ],
exports: [
  SafePipe,
  CheckImage,
  CheckAvatar,
  LimitToPipe,
  DateAgo,
  DurationPipe,
  ReversePipe,
  ArrayStringFilterByPipe,
  StripHtmlPipe
],
  imports: [
    CommonModule,
  ]

})
export class CommonPipesModule { }
